<ng-container *ngIf="managerDetail$ | async; let managerDetail">
    <div class="row pt-3 pb-3">
        <div class="col-lg-12">
            <div class="manager-title d-flex justify-content-between flex-wrap">
                <ul class="manager-name d-flex justify-content-start mb-2">
                    <li>{{ managerDetail?.first_name + ' ' + managerDetail?.last_name }}</li>
                    <li>
                        <!--<i *ngIf="!managerDetail?.archived_for_partner" [ngClass]="{'green': managerDetail?.status === 'active', 'yellow': managerDetail?.status === 'inactive'}" aria-hidden="true" class="fa fa-circle"></i>
              <i *ngIf="managerDetail?.archived_for_partner" [ngClass]="{'red': managerDetail?.archived_for_partner}" aria-hidden="true" class="fa fa-circle"></i> {{ (managerDetail?.status ? (managerDetail?.archived_for_partner ? 'Archived' : managerDetail.status ) : '') | titlecase }} -->
                        <ul class="list-inline manger-detail-status">
                            <li class="badge-label">Status:</li>
                            <li class="badge-stl">
                                <div class="item-status-manager" *ngIf='!managerDetail?.archived_for_partner'
                                    [ngClass]='{"available-status" : managerDetail.active_status === "Available", "not-available-status" :  managerDetail.active_status === "Not Available", "do-not-disturb-status" :  managerDetail.active_status === "Do Not Disturb", "never-logged" : managerDetail.active_status === "Never Logged In" }'>
                                    {{managerDetail?.active_status}}</div>
                                <div class="item-status-manager" *ngIf='managerDetail?.archived_for_partner'
                                    [ngClass]='{"archive-status" : managerDetail?.archived_for_partner === true}'>
                                    {{managerDetail?.archived_for_partner === true ? 'Archived' :
                                    managerDetail?.active_status}}</div>
                            </li>
                        </ul>
                    </li>
                </ul>
                <ul class="edit-delete-list d-flex justify-content-start">
                    <li (click)="toggleArchiveModal()" *ngIf="managerDetail?.archived_for_partner === false">
                        <i class="fa fa-trash" aria-hidden="true"></i> Archive
                    </li>
                    <li (click)="editFromDetailPage(managerDetail.id)"
                        *ngIf="managerDetail?.archived_for_partner === false">
                        <i class="fa fa-pencil" aria-hidden="true"></i> Edit
                    </li>
                    <li (click)="toggleUnarchiveModal()" *ngIf="managerDetail?.archived_for_partner === true">
                        <i class="fa fa-undo" aria-hidden="true"></i> Unarchive
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="row pt-3 pb-3 manager-info">
        <div class="col-lg-4">
            <div class="form-group">
                <label class="pb-2">Email <cite>*</cite></label>
                <span>{{ managerDetail?.email ? managerDetail.email : '' }}</span>
            </div>
            <div class="form-group">
                <label class="pb-2">Designation <cite>*</cite></label>
                <span>{{managerDetail?.designation === 'Others' ? (managerDetail?.defaultDesignation) :
                    (managerDetail.designation | designationMapValue) ? (managerDetail.designation.replace('_', ' ') |
                    designationMapValue) : ''}}</span>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="form-group">
                <div class="row">
                    <div class="col-lg-4 col-4">
                        <label class="pb-2">Country Code <cite>*</cite></label>
                        <span>{{ managerDetail?.country_code ? managerDetail.country_code : '' }}</span>
                    </div>
                    <div class="col-lg-8 col-8">
                        <label class="pb-2">Phone Number <cite>*</cite></label>
                        <span>{{ managerDetail?.phone ? managerDetail.phone : '' }}</span>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="pb-2">Department <cite>*</cite></label>
                <span>{{ managerDetail?.department === 'it' ? 'IT' : (managerDetail?.department === 'cpe' ? 'CPE' :
                    (managerDetail?.department === 'cpm' ? 'CPM' : (managerDetail?.department?.replace('_', ' ') |
                    titlecase)))
                    }}</span>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="form-group">
                <label class="pb-2">Role</label>
                <span>{{ (managerDetail?.role === 'pm') ? 'PM' : 'PM Lead' }}</span>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-lg-6 col-6">
                        <label class="pb-2">Location <cite>*</cite></label>
                        <span>{{(managerDetail?.office ? managerDetail.office.replace('_', ' ') : '') | titlecase
                            }}</span>
                    </div>
                    <div class="col-lg-6 col-6">
                        <label class="pb-2">TimeZone <cite>*</cite></label>
                        <span>{{(managerDetail?.timezone ? managerDetail.timezone : '') | timezoneTextOnly }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12 project-list-main">
            <h4>Buildcard List</h4>
            <ul class="project-list">
                <li>
                    <p>Buildcard Name</p>
                    <p>Buildcard Start Date</p>
                    <p>Status</p>
                    <p>Confidence Parameter</p>
                    <p>Overall Progress</p>
                </li>
                <ng-container *ngFor="let project of managerDetail.projects">
                    <li>
                        <p>{{ project?.name ? project.name : '' }}</p>
                        <p>{{ (project?.start_date ? project.start_date : '') | date }}</p>
                        <p>
                            <button
                                [ngClass]="{'archive-blocked-btn': (project?.status === 'blocked'||project?.status === 'stopped'), 'archive-completed-btn': project?.status === 'completed'}"
                                class="archive-list-btn" type="button">{{ project?.status ? project.status : ''
                                }}</button>
                            {{ project?.current_state.split('_').join(' ') ? project.current_state.split('_').join(' ')
                            : '' }}
                        </p>
                        <p>{{ project?.confidence_parameter }}%</p>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar"
                                [style.width.%]="project?.completion ? project?.completion : 0" aria-valuenow="75"
                                aria-valuemin="0" aria-valuemax="100">{{project?.completion ? project.completion : 0}}%
                            </div>
                        </div>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>
    <!-- <app-manager-archive [projectListOfManagers]="managersAllProject" [archive]='archive'></app-manager-archive> -->
</ng-container>
<app-manager-edit [editUser]="managerDetail$ | async" (managerUpdatedData)="managerUpdatedData($event)">
</app-manager-edit>



<app-custom-popup #customPopUp [customPopupConfig]="customPopupConfig" (sendModalFunc)="getModalFunc($event)">
</app-custom-popup>
<!--Unarchive Modal-->

<!-- <div bsModal #unarchiveModal="bs-modal" class="client-modal delTask-modal modal fade" id="archivePopup" tabindex="-1"
    role="dialog" aria-labelledby="modalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content transparent-modal">
        <div class="modal-body">
          <div class="delete-task-block">
            <h3>
              Unarchive
            </h3>
            <p>Are you sure you want to unarchive this manager?</p>
          </div>
          <div class="form-group btn-block d-flex justify-content-end">
            <button class="btn cancel-btn" (click)='closeUnarchiveModal()' type="button">Cancel
            </button>
            <button class="btn" type="button" (click)="unarchiveManager()">Unarchive</button>
          </div>
        </div>
      </div>
    </div>
  </div> -->