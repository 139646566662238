import { Observable, of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { DataService } from './data.service';
import { environment } from '../../environments/environment';
import { Constants } from '../shared/constants';

@Injectable({
  providedIn: 'root',
})
export class PodService {
  API_BASE_URL = environment.API_BASE_URL;

  constructor(public http: HttpClient, private dataService: DataService) {}

  public getPods(page?, perPage?, query?, value?, min?, withProjects = true) {
    let API = `${this.API_BASE_URL}${Constants.apiEndPoints.getPods}?with_projects=${withProjects}&page=${page}&per_page=${perPage}`;

    if (query) {
      API = `${API}&search=${query}`;
    }
    if (value) {
      API = `${API}&filter=status&value=${value}`;
    }

    if (min) {
      API = `${API}&min=${min}`;
    }

    return this.http
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: HttpErrorResponse) => {
          this.dataService.checkErrorStatus(error);
          return throwError(() => error.error);
        })
      );
  }

  public getAllManagers(projectId, filters, query?) {
    let API =
      this.API_BASE_URL +
      'projects/' +
      projectId +
      '/' +
      Constants.apiEndPoints.getPods +
      '?page=' +
      filters.page +
      '&per_page=' +
      filters.perPage +
      '&designation=' +
      filters.designation;
    if (query) {
      API = `${API}&search=${query}`;
    }
    return this.http
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => res),
        catchError((error: any) => {
          this.dataService.checkErrorStatus(error);
          return throwError(() => error);
        })
      );
  }

  public updatePod(podData, id) {
    let API = this.API_BASE_URL + Constants.apiEndPoints.getPods + '/' + id;
    return this.http
      .put(
        API,
        JSON.stringify(podData),
        this.dataService.getRequestOptionArgs('application/json')
      )
      .pipe(
        map((res) => res),
        catchError((error: any) => {
          this.dataService.checkErrorStatus(error);
          return throwError(() => error);
        })
      );
  }

  public createPod(podData) {
    let API = this.API_BASE_URL + Constants.apiEndPoints.getPods;
    return this.http
      .post(
        API,
        JSON.stringify(podData),
        this.dataService.getRequestOptionArgs('application/json')
      )
      .pipe(
        map((res) => res),
        catchError((error: any) => {
          this.dataService.checkErrorStatus(error);
          return throwError(() => error);
        })
      );
  }
}
