import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  SetToasterSuccessMsg,
  SetToasterErrorMsg,
  ShowToasterMsg,
} from './toaster.action';
import { ToasterModel } from './toaster.model';
@State<ToasterModel>({
  name: 'toasterstate',
  defaults: {
    toastMessage: '',
    time: 2000,
    errorClass: false,
    isToasterEnable: false,
  },
})
@Injectable()
export class ToasterState {
  // isLoading = false;

  @Action(SetToasterSuccessMsg)
  setToasterSuccessState(
    { getState, setState }: StateContext<ToasterModel>,
    { toastMessage, time }: SetToasterSuccessMsg
  ) {
    setState({
      ...getState(),
      toastMessage: toastMessage,
      time: time > 0 ? time : 2000,
      errorClass: false,
    });
  }

  @Action(SetToasterErrorMsg)
  setToasterErrorState(
    { getState, setState }: StateContext<ToasterModel>,
    { toastMessage, time }: SetToasterSuccessMsg
  ) {
    setState({
      ...getState(),
      toastMessage: toastMessage
        ? toastMessage
        : 'Something went wrong. Try after sometime.',
      time: time > 0 ? time : 2000,
      errorClass: true,
    });
  }

  // @Action(ShowToasterMsg)
  // showToasterMsg(
  //   { getState, setState }: StateContext<ToasterModel>,
  //   { isToasterEnable }: ShowToasterMsg
  // ) {
  //   setState({
  //     ...getState(),
  //     isToasterEnable: isToasterEnable,
  //   });
  // }
}
