import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Select } from '@ngxs/store';
import { LoaderSelector } from '../../../shared/store/loader/loader.selector';
import { filter, Observable } from 'rxjs';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'app-time-zone',
  templateUrl: './time-zone.component.html',
  styleUrls: ['./time-zone.component.scss'],
})
export class TimeZoneComponent implements OnInit, OnChanges {
  @Input() timezoneConfig = {
    id: 'time_zone',
    componentName: '',
    saveOrCancelChanges: '', // contains on 'save' or 'cancel',
    selectedData: '',
    disabled: false,
    isRequired: true,
    isFormGrpClassEnable: true,
    isDropdownOnTop: false,
  };
  timezones = [];
  selectedItem = [];
  @Output() sendSelectedTimeZone = new EventEmitter();
  dropdownSettings: any = {};
  timezoneErrorMsg = '';
  dropDownSelect = false;
  @Select(LoaderSelector.isAuthTokenUpdated)
  authTokenUpdated: Observable<boolean>;

  constructor(private dataService: DataService) {
    // if (this.commonService.timezones) {
    //   this.timezones = this.commonService.timezones;
    // } else {
    this.authTokenUpdated
      .pipe(filter((x) => x != undefined || x != null))
      .subscribe((isupdated: boolean) => {
        if (isupdated) {
          this.getTimeZones();
        }
      });
    // }
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: true,
      idField: this.timezoneConfig.id,
      textField: 'timeZone',
      allowSearchFilter: true,
      enableCheckAll: false,
      itemsShowLimit: 5,
      defaultOpen: false,
      closeDropDownOnSelection: true,
    };
  }

  ngOnChanges() {
    this.timezoneErrorMsg = '';
    this.selectBasedOnCompoent();
  }

  selectBasedOnCompoent() {
    if (
      this.timezoneConfig.componentName === 'myAccountComponent' &&
      this.timezoneConfig.saveOrCancelChanges === 'cancel'
    ) {
      this.selectedItem = [this.getUserObject().timezone];
    } else {
      if (
        this.timezoneConfig.componentName !== 'myAccountComponent' &&
        this.timezoneConfig.selectedData &&
        this.timezoneConfig.selectedData.trim() !== ''
      ) {
        this.selectedItem = [this.timezoneConfig.selectedData];
      } else {
        this.selectedItem = [];
      }
    }
    if (this.timezones && this.timezones.length > 0) {
      this.getSelectedTimeZoneValue();
    }
  }

  dropdownInvalid() {
    if (this.dropDownSelect) {
      this.dropDownSelect = false;
      if (this.selectedItem.length < 1 && this.timezoneConfig.isRequired) {
        this.timezoneErrorMsg = 'Please enter the timezone';
      } else {
        this.timezoneErrorMsg = '';
      }
    }
  }

  onItemSelect(item: any) {
    const selected = item;
    this.selectedItem = [selected];
    if (this.timezoneConfig.disabled) {
      this.sendSelectedTimeZone.emit(this.getTimezoneTextOnly(selected));
    } else if (
      this.timezoneConfig.componentName === 'myAccountComponent' &&
      this.timezoneConfig.saveOrCancelChanges === 'cancel'
    ) {
      this.selectedItem = [this.getUserObject().timezone];
      this.sendSelectedTimeZone.emit(this.selectedItem[0]);
    }
  }

  getTimeZones() {
    // this.loaderService.show();
    this.dataService.getUserTimeZoneList().subscribe(
      (timezones) => {
        // this.loaderService.hide();
        this.timezones = timezones;
        if (
          this.timezoneConfig.componentName === 'myAccountComponent' &&
          this.timezoneConfig.saveOrCancelChanges === 'cancel'
        ) {
          this.selectedItem = [this.getUserObject().timezone];
        }
        this.getSelectedTimeZoneValue();
      },
      (error) => {
        // this.loaderService.hide();
      }
    );
  }

  getUserObject() {
    return this.dataService.user;
  }

  getSelectedTimeZoneValue() {
    if (this.selectedItem.length > 0) {
      this.selectedItem[0] =
        this.selectedItem[0] === 'Asia/Calcutta'
          ? 'Asia/Kolkata'
          : this.selectedItem[0];
      let value = this.timezones.filter((element) =>
        element.includes(this.selectedItem)
      );
      this.selectedItem = value;
    } else {
      this.selectedItem = [];
    }
  }

  getTimezoneTextOnly(timezone) {
    if (timezone.split('+').length > 1) {
      return timezone.split('+')[0].trim();
    } else if (timezone.split('-').length > 1) {
      return timezone.split('-')[0].trim();
    } else {
      return timezone.trim().split(' ')[0].trim();
    }
  }
}
