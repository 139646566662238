import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timezoneTextOnly',
})
export class timezoneTextOnly implements PipeTransform {
  transform(timezone: string) {
    if (timezone.split('+').length > 1) {
      return timezone.split('+')[0].trim();
    } else if (timezone.split('-').length > 1) {
      return timezone.split('-')[0].trim();
    } else {
      return timezone.trim().split(' ')[0].trim();
    }
  }
}
