import { Component, Input, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { ToasterModel } from '@shared/store/toaster/toaster.model';
import { ToasterSelector } from '@shared/store/toaster/toaster.selector';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
})
export class ToasterComponent implements OnInit {
  @Select(ToasterSelector.getToastMessage)
  getToastMessage$: Observable<ToasterModel>;

  @Input() showToasterMsg = false;
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {
    if (this.showToasterMsg) {
      this.getToastMessage$.subscribe((data) => {
        if (data) {
          data.time = data.time ? data.time : 2000;
          this.showToasterMsg = true;
          setTimeout(() => {
            this.showToasterMsg = false;
          }, data.time);
        }
      });
    }
  }

  closeSaveTost() {
    this.showToasterMsg = false;
  }
}
