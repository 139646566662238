import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { IsAuthTokeUpdated, SetIsLoaderEnable } from './loader.action';
import { LoaderModel } from './loader.model';
@State<LoaderModel>({
  name: 'loaderstate',
  defaults: {
    isLoader: false,
    isPaginationLoader: false,
    title: 'loader',
    isAuthTokenUpdated: false,
  },
})
@Injectable()
export class LoaderState {
  // isLoading = false;

  @Action(SetIsLoaderEnable)
  setLoaderState(
    { getState, setState }: StateContext<LoaderModel>,
    { payload }: SetIsLoaderEnable
  ) {
    setState({
      ...getState(),
      isLoader: payload,
    });
    // this.isLoading = isEnable;
  }

  @Action(IsAuthTokeUpdated)
  authTokenUpdated(
    { getState, setState }: StateContext<LoaderModel>,
    { payload }: IsAuthTokeUpdated
  ) {
    setState({
      ...getState(),
      isAuthTokenUpdated: payload,
    });
  }
}
