import {
  AddManager,
  DeleteManager,
  UpdateManager,
  GetAllManager,
  GetManager,
  ReInviteUser,
  SetManagerSquadTab,
  UpdateManagerStatus,
} from './manager.action';
import { ManagerStateModel } from './manager-state.model';
import { ManagerSelector } from './manager.selector';
import { ManagerState } from './manager.state';

export const Manager = [
  ManagerState,
  AddManager,
  DeleteManager,
  UpdateManager,
  GetAllManager,
  GetManager,
  ReInviteUser,
  UpdateManagerStatus,
  SetManagerSquadTab,
  ManagerStateModel,
  ManagerSelector,
];
