<app-custom-modal #addEditSquadForm (modalClosed)="modalClosed($event)">
    <div class="modal-dialog modal-dialog-top" role="document">
        <div class="modal-content transparent-modal">
            <div class="modal-body">
                <h2 style="font-size: 1.5rem;"> {{ editMode ? 'Update Squad': 'Create Squad'}} </h2>
                <div class="popup-remove" (click)="popupHide()">
                    <img src="../../assets/images/popup-remove.svg" />
                </div>
                <div class="pod-form">
                    <div class="form-wrp">
                        <div class="form-wrp-inner">
                            <label for="name">Squad Name</label>
                            <div class="input-wrp">
                                <input type="text" [(ngModel)]="podName" (input)="onSearchChange()" placeholder="Name"
                                    name="name" />
                                <span class="helper-name">
                                    <span class="hyphen-text"></span>
                                    <span class="name-text">Squad</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="form-wrp">
                        <div class="form-wrp-inner">
                            <label>Squad Members <span
                                    *ngIf="InputSquad?.users?.length > 0">({{InputSquad?.users?.length}})</span></label>
                            <div class="input-wrp">
                                <div class="tag-input-wrp">
                                    <div class="tagwrp-input" (click)="tagInputClick()">
                                        <span *ngFor="let member of InputSquad?.users;" class="tag-item"
                                            (click)="$event.stopPropagation()">{{member.first_name + ' ' +
                                            member.last_name}} <span (click)="removeMember($event,member)"
                                                class="remove-pod"></span></span>

                                        <span class="tag-editable" id="editable" contenteditable="" #tagInput
                                            (keyup)="searchChange($event)" (focus)="cursorPosition()"></span>
                                    </div>
                                </div>
                                <div class="custom-dropdown" *ngIf="showManager">
                                    <div class="pagination-loader keyword-loader" *ngIf='isSearchLoader'>
                                        <img src="assets/images/pagination-loader.gif" alt="">
                                    </div>
                                    <ul #memberScrollStart infiniteScroll [infiniteScrollDistance]="2"
                                        [infiniteScrollThrottle]="50" (scrolled)="onScrollMember()"
                                        [scrollWindow]="false" class="drop-down-list">
                                        <ng-container *ngIf="!searchText">
                                            <li *ngFor="let user of managers; let i = index">
                                                <label>
                                                    <input (click)="checkPod(user)" [(ngModel)]="user['checked']"
                                                        type="checkbox" />
                                                    <span class="custom-checkbox-revamp"> {{user['first_name'] + ' ' +
                                                        user['last_name']}} ({{user['designation'] |
                                                        designationMapValue}})</span>
                                                </label>
                                            </li>
                                        </ng-container>
                                        <ng-container *ngIf="searchText">
                                            <li *ngFor="let user of searchedUsersList; let i = index">
                                                <label>
                                                    <input (click)="checkPod(user)" [(ngModel)]="user['checked']"
                                                        type="checkbox" />
                                                    <span class="custom-checkbox-revamp"> {{user['first_name'] + ' ' +
                                                        user['last_name']}} ({{user['designation'] |
                                                        designationMapValue}})</span>
                                                </label>
                                            </li>
                                        </ng-container>

                                    </ul>
                                    <div class="pagination-loader load-more-pages" *ngIf='isShowPaginationLoader'>
                                        <img src="assets/images/pagination-loader.gif" alt="">
                                    </div>
                                    <div class="done-state">
                                        <button (click)="savePodMembers()" class="btn done-btn">Done</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-wrp">
                        <div class="form-wrp-inner">
                            <div class="custom-radio-reavamp text-start">
                                <label for="isStorePod1">
                                    <input (click)="toggleStorePod($event)" id="isStorePod1" type="checkbox"
                                        [checked]="isStorePod" name="storePod">
                                    <!-- <span></span> -->
                                    <cite>Is this a Studio Store Squad?</cite>
                                </label>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="podStoreProjects$ | async; let podStoreProjects">
                        <div class="form-wrp"
                            *ngIf="isStorePod && InputSquad?.is_store_pod === false && podStoreProjects?.length > 0">
                            <div class="form-wrp-inner">
                                <p class="assigntopodwarn">All Studio Store buildcards will be assigned to
                                    {{InputSquad?.name}}.</p>
                                <div class="scroll-all-project" *ngIf="podStoreProjects?.length > 0">
                                    <ul class="ps-0">
                                        <li *ngFor="let pod of podStoreProjects">{{pod?.name}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="form-wrp text-end">
                        <button class="btn revamp-submit-btn"
                            [disabled]="!(podName && InputSquad?.users?.length > 0) || editMode && disableSave"
                            (click)="createPod()">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-custom-modal>