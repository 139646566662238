<div class="manager-card" (click)="goToManagerDetail(user.id)">
    <div class="manager-header">
        <div class="header-left w-100">
            <h4 class="text-capitalize" [ngClass]="{'fullWidth': user?.archived_for_partner}">{{
                (user?.first_name ? user.first_name : '') + ' ' + (user?.last_name ? user.last_name : '') }}
            </h4>
        </div>
        <div class="header-right" *ngIf="user && !user?.archived_for_partner && userType === 'pm_lead'">
            <p class="edit-icon" (click)="$event.stopPropagation(); editManager(user)">
                <i aria-hidden="true" class="fa fa-pencil"></i> Edit
            </p>
        </div>
    </div>
    <ul class="manager-detail">
        <li>Email :
            <strong>{{ user?.email ? user.email : '' }}</strong>
        </li>
        <li> Location :
            <strong>{{ (user?.office ? user.office.replace('_', ' ') : '') | titlecase }}
                <span *ngIf="(user?.timezone)"> ({{user?.timezone}})</span>
            </strong>
        </li>
        <li>Phone Number :
            <strong>{{ user?.phone ? (user?.country_code ? (user.country_code + '-' + user.phone) :
                user.phone)
                : '' }}</strong>
        </li>
        <li>Designation :
            <strong>{{ (user?.designation === 'Others' ? user?.defaultDesignation : (user?.designation |
                designationMapValue)) }}</strong>
        </li>
        <li>Department :
            <strong>{{ user?.department === 'it' ? 'IT' : (user?.department === 'cpe' ? 'CPE' :
                (user?.department === 'cpm' ? 'CPM' : (user?.department?.replace('_', ' ') | titlecase)))
                }}</strong>
        </li>
    </ul>
    <div class="manager-card-bottom-list">
        <table class="w-100">
            <tr>
                <td>No. of Buildcards
                    <strong>{{ user?.projects_count + user?.archive_projects_count}}</strong>
                </td>
                <td>Role
                    <strong>{{ (user?.role === 'pm') ? 'PM' : 'PM Lead'}}</strong>
                </td>
                <td>Status
                    <strong>
                        <div class="manager-view-status">
                            <div *ngIf='user?.archived_for_partner' class="item-status-manager"
                                [ngClass]='{"archive-status" : user?.archived_for_partner === true}'>
                                {{user?.archived_for_partner === true ? 'Archived' : user?.active_status}}
                            </div>
                            <div *ngIf='!user?.archived_for_partner' class="item-status-manager"
                                [ngClass]='{"available-status" : user?.active_status === "Available", "not-available-status" :  user?.active_status === "Not Available", "do-not-disturb-status" :  user?.active_status === "Do Not Disturb", "never-logged" : user?.active_status === "Never Logged In" }'>
                                <button class="btn send-invite" [disabled]="isInviteEnable"
                                    (click)="$event.stopPropagation();sendReInvite(user);"
                                    *ngIf="user?.active_status === 'Never Logged In'">
                                    Send Invite
                                </button>
                                <span *ngIf="user?.active_status !== 'Never Logged In'">
                                    {{user?.active_status ? user?.active_status : ''}}
                                </span>
                            </div>
                        </div>
                    </strong>
                </td>
            </tr>
        </table>
        <!-- <li>No. of Projects
                <strong>{{ user?.projects_count + user?.archive_projects_count}}</strong>
              </li>
              <li>Role
                <strong>{{ (user?.role === 'pm') ? 'PM' : 'PM Lead'}}</strong>
              </li>
              <li>
                <div class="manager-view-status">
                    <div *ngIf='user?.archived_for_partner' class="item-status-manager" [ngClass]='{"archive-status" : user?.archived_for_partner === true}'>{{user?.archived_for_partner === true ? 'Archived' : user?.active_status}}</div>
                    <div *ngIf='!user?.archived_for_partner' class="item-status-manager" [ngClass]='{"available-status" : user.active_status === "Available", "not-available-status" :  user.active_status === "Not Available", "do-not-disturb-status" :  user.active_status === "Do Not Disturb", "never-logged" : user.active_status === "Never Logged In" }'>{{user?.active_status ? user?.active_status : ''}}</div>
                </div>   
              </li> -->
    </div>
</div>