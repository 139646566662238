// when need to trigger a function ( such as in case of while searching ) but only once, the default timing is 800ms
// but we can pass any number of seconds we want as per the requirement
// one such example can be seen in add-edit-pod.component.ts
// searchChange = debounce((event) => this.onKeydownEvent(event));
// and we are calling searchChange from the template file
export function debounce(func, timeout = 800) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}
