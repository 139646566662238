import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-custom-popup',
  templateUrl: './custom-popup.component.html',
  styleUrls: ['./custom-popup.component.scss'],
})
// This modal popup is used only for alert or notification purpose
export class CustomPopupComponent implements OnInit {
  @Input() customPopupConfig = {
    modalId: '',
    componentName: '',
    class: '',
    title: '',
    content: '',
    buttons: [
      {
        name: '',
        id: '',
        class: '',
      },
    ],
    closeEnable: false,
  };
  @Output() sendModalFunc = new EventEmitter();
  @ViewChild('customPopup', { static: true }) customPopup: ModalDirective;
  constructor() {}

  ngOnInit(): void {}

  modalFunc(modalId, buttonId) {
    this.customPopup.hide();
    const clickedButtonwithModal = {
      modalId: modalId,
      buttonId: buttonId,
    };
    this.sendModalFunc.emit(clickedButtonwithModal);
  }

  close() {
    this.customPopup.hide();
    this.sendModalFunc.emit('');
  }
}
