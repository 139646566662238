import { ManagerModel } from './manager.model';

//Here we define four actions for CRUD operations respectively
export enum ManagerActionTypes {
  ADD_ONE = '[Managers] Add One',
  UPDATE_ONE = '[Managers] Update One',
  DELETE_ONE = '[Managers] Delete One',
  GET_ALL = '[Managers] Get All',
  GET_ONE = '[Managers] Get One',
  RE_INVITE_USER = '[Managers] ReInvite User',
  UPDATE_MANAGER_STATUS = '[Managers] Update Manager Status',
  SET_MANAGER_SQUAD_TAB = 'Manager Squad Tab',
}

//Read All
export class GetAllManager {
  static readonly type = ManagerActionTypes.GET_ALL;
  constructor(
    public page: number,
    public perPage: number,
    public searchText: string,
    public value: string,
    public min: boolean
  ) {}
  // constructor(){}
}

//Set manager squad tab
export class SetManagerSquadTab {
  static readonly type = 'Manager Squad Tab';
  constructor(public payload: string) {}
}

//Create
export class AddManager {
  static readonly type = '[Managers] Add';
  constructor(public payload: any) {}
}

//Update
export class UpdateManager {
  static readonly type = '[Managers] Update';
  constructor(public payload: any, public id: number) {}
}

//Read
export class GetManager {
  static readonly type = ManagerActionTypes.GET_ONE;
  constructor(public id: number) {}
}

//ReInvite User
export class ReInviteUser {
  static readonly type = ManagerActionTypes.RE_INVITE_USER;
  constructor() {}
}

// Update Manager Status to Archive or UnArchive
export class UpdateManagerStatus {
  static readonly type = ManagerActionTypes.UPDATE_MANAGER_STATUS;
  constructor(public managerData: ManagerModel, public type: boolean) {}
}

//Delete
export class DeleteManager {
  static readonly type = '[Managers] Delete';
  constructor(public id: number) {}
}

export type ManagerActions =
  | AddManager
  | UpdateManager
  | DeleteManager
  | GetAllManager
  | GetManager
  | ReInviteUser
  | UpdateManagerStatus
  | SetManagerSquadTab;
