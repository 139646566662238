<div class="initialLoader toasterwrap" *ngIf='showToasterMsg'>
    <div class="loadingOverlay"></div>
    <ng-container *ngIf="getToastMessage$ | async; let  getToastMessage">
        <div class="project-success-msg" [ngClass]="{'project-error-msg' : getToastMessage['errorClass'] === true}">
            <img src="../../../../assets/images/toaster.svg" alt="">
            <h3>
                {{ getToastMessage['toastMessage'] }}
            </h3>
            <a class="ms-auto" (click)='closeSaveTost()'>
                <i class="fa fa-times" aria-hidden="true"></i>
            </a>
        </div>
    </ng-container>
</div>