<form [formGroup]="managerForm" (ngSubmit)="formSubmit()">
    <div class="manager-form-modal">
        <div class="row">
            <div class="col-sm-6 form-group px-3">
                <label class="mb-2 mt-3">
                    First Name
                    <em>*</em>
                </label>
                <input (keypress)="allowAlphabets($event)" class="form-control text-capitalize mb-sm-3 mb-lg-auto"
                    type="text" formControlName="first_name" placeholder="First Name"
                    (keydown.space)="$event.preventDefault()" />
                <div *ngIf="firstName.touched && firstName.invalid" class="invalid-feedback">
                    <div *ngIf="firstName.errors['required']">First name is required</div>
                    <div *ngIf="firstName.errors['minlength']">
                        First name should be minimum 3 characters
                    </div>
                </div>
            </div>

            <div class="col-sm-6 form-group px-3">
                <label class="mb-2 mt-3">
                    Last Name
                    <em>*</em>
                </label>
                <input (keypress)="allowAlphabets($event)" class="form-control text-capitalize" type="text"
                    formControlName="last_name" placeholder="Last Name" (keydown.space)="$event.preventDefault()" />
                <div *ngIf="lastName.touched && lastName.invalid" class="invalid-feedback">
                    <div *ngIf="lastName.errors['required']">Last name is required</div>
                    <div *ngIf="lastName.errors['minlength']">
                        Last name should be minimum 3 characters
                    </div>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-sm-12 px-3">
                <label class="mb-2 mt-3 px-0">
                    Email
                    <em>*</em>
                </label>
                <input class="form-control" type="email" formControlName="email" [ngClass]="{ 'grey-box': manager }"
                    pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
                    placeholder="cpe.name@engineer.ai" />

                <div *ngIf="email.touched && email.invalid" class="invalid-feedback">
                    <div *ngIf="email.errors['required']">Email is required</div>
                    <div *ngIf="email.errors['pattern']">
                        Email should be in correct format
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-4 form-group px-3">
                <label class="mb-2 mt-3">Country Code
                    <em>*</em>
                </label>
                <div>
                    <international-phone-number id="international_number" placeholder=""
                        [(ngModel)]="countryCodeValue.value" [ngModelOptions]="{ standalone: true }"
                        (ngModelChange)="countryCodeChange()" [defaultCountry]="cCodeValue ? cCodeValue : 'in'"
                        #countryCodeValue name="international_phone">
                    </international-phone-number>
                    <div class="input-val">
                        {{ countryCodeValue.value ? countryCodeValue.value : '+91' }}
                    </div>
                </div>
            </div>
            <div class="col-sm-8 form-group px-3">
                <label class="mb-2 mt-3">
                    Phone Number
                    <em>*</em>
                </label>

                <input class="form-control phone-inp" type="text" formControlName="phone" [attr.minlength]="
                  office.value === 'dubai' ||
                  countryCodeValue.value === '971' ||
                  countryCodeValue.value === '+971'
                    ? 9
                    : 10
                " [attr.maxlength]="
                  office.value === 'dubai' ||
                  countryCodeValue.value === '971' ||
                  countryCodeValue.value === '+971'
                    ? 9
                    : 10
                " placeholder="9865878956" (keypress)="onlyNumber($event)" />
                <div *ngIf="phone && phone.touched && !phone.valid" class="invalid-feedback">
                    <div *ngIf="phone.errors['required']">Phone no. is required </div>
                    <div *ngIf="phone.errors['pattern']">
                        Phone no. should have
                        {{
                        office.value === 'dubai' ||
                        countryCodeValue.value === '971' ||
                        countryCodeValue.value === '+971'
                        ? '9'
                        : '10'
                        }}
                        digits
                    </div>
                </div>
            </div>
        </div>


        <div class="row px-3 mb-3">
            <label class="mb-2 mt-3 px-0">
                Designation {{designation.value}}
                <em>*</em>
            </label>
            <div class="select-box px-0">
                <select class="form-control" name="designation" formControlName="designation" (change)="desgChange()">
                    <option disabled value="">Select</option>
                    <option *ngFor="let designtion of designations" value="{{ designtion.key }}">
                        {{ designtion.value }}
                    </option>
                </select>
            </div>
            <div *ngIf="designation.touched && designation.invalid" class="invalid-feedback">
                <div *ngIf="designation.errors['required']">
                    Designation is required
                </div>
            </div>
        </div>
        <div class="row px-3" *ngIf="designation.value === 'Others'">
            <input class="form-control" placeholder="Please Specify Designation" type="text" name="defaultDesignation"
                formControlName="defaultDesignation" />
            <div class="invalid-feedback">{{ errorMessage }}</div>
        </div>

        <div class="row px-3">
            <label class="mb-2 mt-3 px-0">Department <em>*</em></label>
            <div class="select-box mb-sm-3 mb-lg-auto px-0">
                <select class="form-control" formControlName="department">
                    <option disabled value="">Select</option>
                    <option value="delivery">Delivery</option>
                    <option *ngFor="let department of departments" value="{{ department.d_value }}">
                        {{ department.d_name }}
                    </option>
                </select>
            </div>
            <div *ngIf="department.touched && department.invalid" class="invalid-feedback">
                <div *ngIf="department.errors['required']">Department is required</div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-6 form-group px-3">
                <label class="mb-2 mt-3">Location <em>*</em></label>
                <div class="select-box">
                    <select class="form-control" (change)="onOfficeChange()" formControlName="office">
                        <option disabled value="">Select</option>
                        <option *ngFor="let office of offices" value="{{ office.o_value }}">
                            {{ office.o_name }}
                        </option>
                    </select>
                </div>
                <div *ngIf="office.touched && office.invalid" class="invalid-feedback">
                    <div *ngIf="office.errors['required']">Location is required</div>
                </div>
            </div>
            <div class="col-sm-6 px-3 mt-3">
                <app-time-zone [timezoneConfig]="timezoneConfig" (sendSelectedTimeZone)="getSelectedTimezone($event)">
                </app-time-zone>
            </div>
        </div>

    </div>
    <ng-content></ng-content>
</form>