import { Component, OnInit, ViewChild } from '@angular/core';
import { Actions, Select, Store } from '@ngxs/store';
import { ManagerState } from '../shared/store/manager/manager.state';
import { filter, mergeMap, Observable, shareReplay } from 'rxjs';
import {
  GetAllManager,
  SetManagerSquadTab,
} from '../shared/store/manager/manager.action';
import { DataService } from '../services/data.service';
import { ManagerService } from '../services/manager.service';
import { ManagerAddComponent } from '../manager-add/manager-add.component';
import { GetPods } from '@shared/store/pod/pod.action';
import { ManagerSelector } from '@shared/store/manager/manager.selector';
import { ManagerStateModel } from '@shared/store/manager/manager-state.model';
import { PodModel } from '@shared/store/pod/pod.model';
import { PodSelector } from '@shared/store/pod/pod.selector';
import { ManagerEditComponent } from '../manager-edit/manager-edit.component';
import { AddEditPodComponent } from '../pod-detail/add-edit-pod-modal/add-edit-pod.component';
import { SetIsLoaderEnable } from '../shared/store/loader/loader.action';
import { debounce } from '../utility/utility.functions';
import { SetToasterErrorMsg } from '@shared/store/toaster/toaster.action';
import { LoaderSelector } from '@shared/store/loader/loader.selector';

@Component({
  selector: 'app-manager-list',
  templateUrl: './manager-list.component.html',
  styleUrls: ['./manager-list.component.scss'],
})
export class ManagerListComponent implements OnInit {
  @Select(ManagerSelector.getManagerList) managerData$: Observable<
    ManagerStateModel[]
  >;

  @Select(ManagerSelector.getManagerFullResponse)
  getManagerFullResponse$: Observable<ManagerStateModel>;

  @Select(ManagerSelector.getSearchedUsersList) searchManagerData$: Observable<
    ManagerStateModel[]
  >;
  @Select(ManagerSelector.getManagerSquadTab) managerSquadTab$: Observable<
    ManagerStateModel[]
  >;
  @Select(ManagerSelector.isLoading) managerLoader$: Observable<boolean>;
  @Select(PodSelector.getPodList) podData$: Observable<PodModel[]>;
  @Select(LoaderSelector.isAuthTokenUpdated)
  authTokenUpdated: Observable<boolean>;
  @Select(PodSelector.isLoading)
  podIsLoading$: Observable<boolean>;

  managers = [];
  page = 1;
  throttle = 200;
  scrollDistance = 2;
  managerTab: 'proManager-tab' | 'archivedManager-tab' | 'pod-tab' =
    'proManager-tab';
  isArchived: 'archived' | 'unarchived' = 'unarchived';
  isDataLoaded = false;
  isScroll = false;
  editManager: any;
  updateManagerFlag = false;
  searchText = '';
  @ViewChild('scrollStart') scrollStart;
  @ViewChild(ManagerAddComponent) managerAddComponent: ManagerAddComponent;
  @ViewChild(ManagerEditComponent) managerEditComponent: ManagerEditComponent;
  // @ViewChild('addSquadForm') addSquadForm: AddEditPodComponent;
  @ViewChild(AddEditPodComponent) addEditPodComponent: AddEditPodComponent;
  searchChange = debounce((event) => this.searchManager(event));
  editMode = true;
  editSelectedPod = {};
  managerDataList$: Observable<ManagerStateModel[]>;
  constructor(
    public dataService: DataService,
    private store: Store,
    public managerService: ManagerService
  ) {
    this.managerDataList$ = this.managerData$.pipe(shareReplay(1));
  }

  ngOnInit(): void {
    this.managerTab = this.managerService.managerTabStatus
      ? this.managerService.managerTabStatus
      : 'proManager-tab';
    this.authTokenUpdated
      .pipe(filter((x) => x != undefined || x != null))
      .subscribe((isupdated: boolean) => {
        if (isupdated) {
          this.store.dispatch(new SetIsLoaderEnable(true));
          this.store
            .dispatch([
              new SetManagerSquadTab('proManager-tab'),
              new GetAllManager(
                this.page,
                15,
                this.searchText,
                this.isArchived,
                false
              ),
            ])
            .subscribe(
              (data) => {
                this.isDataLoaded = true;
                this.store.dispatch([
                  new SetIsLoaderEnable(false),
                  new GetPods(this.page, 15),
                ]);
              },
              (error) => {
                this.isDataLoaded = true;
                this.store.dispatch([
                  new SetIsLoaderEnable(false),
                  new SetToasterErrorMsg(),
                ]);
              }
            );
        }
      });
    // this.getManagerList();
  }

  addManager() {
    this.managerAddComponent.resetManager();
    this.managerAddComponent.openModal();
    this.updateManagerFlag = true;
  }

  addPodMember(isEditMode) {
    this.addEditPodComponent.resetPod();
    this.editMode = isEditMode;
    this.addEditPodComponent.addEditSquadForm.showHideModal(true);
  }

  editManagerInList(data) {
    this.updateManagerFlag = true;
    this.editManager = data;
    this.page = 1;
    this.managerEditComponent.openModal();
  }

  managerTabChange(event) {
    this.managerTab = event;
    this.store.dispatch(new SetManagerSquadTab(event));
    if (event !== 'pod-tab') {
      this.isArchived =
        this.managerTab === 'archivedManager-tab' ? 'archived' : 'unarchived';
      this.page = 1;
      this.getManagerList();
    }
  }

  onScrollDown() {
    if (this.managerTab !== 'pod-tab') {
      this.isScroll = true;
      this.getManagerFullResponse$.subscribe((response) => {
        if (response['users']?.length > 0) {
          this.managerLoader$.subscribe((isLoading) => {
            if (
              !isLoading &&
              this.isScroll &&
              this.page < response['total_pages']
            ) {
              this.isScroll = false;
              ++this.page;
              this.getManagerList(true);
            }
          });
        }
      });
    }
  }

  searchManager(event) {
    if (event.keyCode != 9 && event.keyCode != 91) {
      this.page = 1;
      this.getManagerList();
    }
  }

  getManagerList(isScroll?) {
    if (!isScroll) {
      this.store.dispatch(new SetIsLoaderEnable(true));
    }
    this.store
      .dispatch(
        new GetAllManager(this.page, 15, this.searchText, this.isArchived, null)
      )
      .subscribe(
        (data) => {
          if (!isScroll) {
            this.store.dispatch(new SetIsLoaderEnable(false));
          }
        },
        (error) => {
          this.store.dispatch([
            new SetIsLoaderEnable(false),
            new SetToasterErrorMsg(),
          ]);
        }
      );
  }

  updateManagerCall(data) {
    this.updateManagerFlag = false;
  }

  managerUpdatedData(updatedData) {
    this.managerService.managerID = updatedData.data.user.id;
    const index = this.managers.findIndex((indexUpdated) => {
      return this.managerService.managerID === indexUpdated.id;
    });
    this.managers[index] = updatedData.data.user;
    this.updateManagerFlag = false;
  }

  onPodEdit(pod) {
    this.addEditPodComponent.resetPod();
    this.editSelectedPod = { ...pod };
    if (this.editSelectedPod) {
      this.addEditPodComponent.podName = pod.name.replace(' Squad', '');
      this.addEditPodComponent.selectedSquad = [...pod.users];
      this.editMode = true;
      this.addEditPodComponent.isStorePod = pod.is_store_pod;
      // this.addEditPodComponent.pod =  {...pod};
    }
    this.addEditPodComponent.addEditSquadForm.showHideModal(true);
  }
}
