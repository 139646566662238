export const environment = {
  production: false,
  API_BASE_URL: 'https://api-staging-pmdashboard.engineer.ai/api/v1/pm/',
  WEB_SOCKET_URL: 'ws://api-staging-pmdashboard.engineer.ai/cable',
  BASE_URL: 'https://staging-pmdashboard.engineer.ai/#/',
  API_BASE_URL_FOR_ACCOUNTS: 'https://api-staging-builder.engineer.ai/',
  apptoken: 'iCxBWGyfgL89NPCwkc-i',
  API_BASE_URL_PAYMENT: 'http://staging-payments.engineer.ai/',
  PAYMENT_TOKEN:
    'GT2CLMJrWD5Iy/K1pi8n6XZn8m1HUXy5fBrQbSIomzv81tYODhQQQDN117aOXJBtMVv9bxN0bLRSvJCoX8/+Ww==',
  ZOOMCLIENTID: 'g01JCPqZSIKB2Ne7ru0Sww',
  GOOGLE_CLIENT_ID:
    '930963822265-4r70gvcgcbm9fp3oid19dd8a9t0ga8e6.apps.googleusercontent.com',
  GOOGLE_CLIENT_SECRET_KEY: '8Fzwv0B1RJZxJ7BKKSWwosEk',
  GITLABURL: 'https://staging.gitlab.builder.ai/clientprojects',
  FRONTAPP_AUTH_TOKEN:
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzY29wZXMiOlsicHJpdmF0ZToqIiwicHJvdmlzaW9uaW5nIiwic2NpbSIsInRpbTo1NDYyNyIsInRpbToyNjk3NjMiLCJ0aW06MjY5ODI3IiwidGltOjI2ODg2NyIsInRpbToyNjg4MDMiLCJ0aW06MjcwNzg3IiwidGltOjI2OTYzNSIsInRpbToyNjk1NzEiLCJ0aW06MjcwMzM5IiwidGltOjI2OTY5OSIsInRpbToyNjY2OTEiLCJ0aW06NTcwMTkiLCJ0aW06MjY5NTA3Iiwic2hhcmVkOioiXSwiaWF0IjoxNTc1ODkwMjUxLCJpc3MiOiJmcm9udCIsInN1YiI6ImVuZ2luZWVyX2FpIiwianRpIjoiNjE0MWRlNzg1ODY2ZTc4NCJ9.SWn0Dbi-ZGiJcWLYPGMHSocYuLXXu4QgdmHRxhokGck',
  FRONTAPPURL: 'https://api2.frontapp.com',
  SCHEDULER_URL:
    'https://staging-scheduler.builder.ai/?token=tpdpdxTvmdfGFyDpWTaK',
  SFID_URL:
    'https://engineerai--newprocess.lightning.force.com/lightning/r/Build_card_object_spec__c/SF_BuildCard_ID/view',
  BUILDER_STUDIO_URL: 'https://studio.builder.ai/',
  ENABLE_SENTRY: false,
  ENABLE_SERVICE_WORKER: false,
  name: 'staging',
};
