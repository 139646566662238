import { Component, OnInit, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { RouterSelectors } from '../shared/store/router/router.selector';
import { Observable } from 'rxjs';
import { RouterState } from '@ngxs/router-plugin';
import {
  GetManager,
  UpdateManagerStatus,
} from '@shared/store/manager/manager.action';
import { ManagerSelector } from '@shared/store/manager/manager.selector';
import { ManagerModel } from '@shared/store/manager/manager.model';
import { ManagerService } from '../services/manager.service';
import { ManagerEditComponent } from '../manager-edit/manager-edit.component';
import { SetIsLoaderEnable } from '@shared/store/loader/loader.action';
import { CustomPopupComponent } from '@shared/component/custom-popup/custom-popup.component';
import {
  SetToasterErrorMsg,
  SetToasterSuccessMsg,
} from '@shared/store/toaster/toaster.action';

@Component({
  selector: 'app-manager-detail',
  templateUrl: './manager-detail.component.html',
  styleUrls: ['./manager-detail.component.scss'],
})
export class ManagerDetailComponent implements OnInit {
  managerId: number;
  managerDetail: ManagerModel;
  customPopupConfig = {
    modalId: 'archive-unarchive',
    componentName: 'ManagerDetailComponent',
    class: 'modal-lg',
    title: 'Unarchive',
    content: 'Are you sure you want to unarchive this manager?',
    buttons: [
      {
        name: 'Cancel',
        id: 'cancel',
        class: '',
      },
      {
        name: 'Unarchive',
        id: 'unarchive',
        class: '',
      },
    ],
    closeEnable: false,
  };

  @ViewChild(CustomPopupComponent) modalPopup: CustomPopupComponent;

  @ViewChild(ManagerEditComponent, { static: true })
  managerEditComponent: ManagerEditComponent;

  @Select(ManagerSelector.getManagerFullResponse)
  managerDetail$: Observable<ManagerModel>;

  @Select(RouterState.state) routerParam$: Observable<boolean>;
  constructor(private store: Store, private managerService: ManagerService) {}

  ngOnInit(): void {
    this.routerParam$.subscribe((state) => {
      if (state && state['params']?.id) {
        this.managerId = state['params'].id;
        this.getManager();
      }
    });
  }

  getManager() {
    this.store
      .dispatch([new SetIsLoaderEnable(true), new GetManager(this.managerId)])
      .subscribe(([, data]) => {
        this.managerDetail = data.managerstate.entities;
        this.store.dispatch([new SetIsLoaderEnable(false)]);
      });
  }

  managerUpdatedData(updatedData) {
    this.managerService.managerID = updatedData.data.user.id;
  }

  toggleArchiveModal() {
    this.customPopupConfig = {
      modalId: 'archive',
      componentName: 'ManagerDetailComponent',
      class: 'modal-lg',
      title: 'Archive Manager',
      content: 'Are you sure you want to archive this manager?',
      buttons: [
        {
          name: 'Cancel',
          id: 'cancel',
          class: 'btn-cancel',
        },
        {
          name: 'Archive',
          id: 'archive',
          class: 'btn-error',
        },
      ],
      closeEnable: false,
    };
    this.modalPopup.customPopup.show();
  }

  editFromDetailPage(id) {
    this.managerService.managerID = id;
    this.managerEditComponent.openModal();
  }

  toggleUnarchiveModal() {
    this.customPopupConfig = {
      modalId: 'unarchive',
      componentName: 'ManagerDetailComponent',
      class: 'modal-lg',
      title: 'Unarchive Manager',
      content: 'Are you sure you want to unarchive this manager?',
      buttons: [
        {
          name: 'Cancel',
          id: 'cancel',
          class: 'btn-cancel',
        },
        {
          name: 'Unarchive',
          id: 'unarchive',
          class: 'btn-error',
        },
      ],
      closeEnable: false,
    };
    this.modalPopup.customPopup.show();
  }

  getModalFunc(event) {
    if (event.modalId === 'unarchive' && event.buttonId === 'unarchive') {
      this.unArchiveManager();
    } else if (event.modalId === 'archive' && event.buttonId === 'archive') {
      this.archiveManager();
    }
  }

  unArchiveManager() {
    this.store
      .dispatch([
        new SetIsLoaderEnable(true),
        new UpdateManagerStatus(this.managerDetail, false),
      ])
      .subscribe((data) => {
        if (data) {
          this.store.dispatch([
            new SetIsLoaderEnable(false),
            new SetToasterSuccessMsg('Manager has been unarchived.'),
          ]);
          this.getManager();
        } else {
          this.store.dispatch([
            new SetIsLoaderEnable(false),
            new SetToasterErrorMsg(),
          ]);
        }
      });
  }

  archiveManager() {
    this.store
      .dispatch([
        new SetIsLoaderEnable(true),
        new UpdateManagerStatus(this.managerDetail, true),
      ])
      .subscribe((data) => {
        if (data) {
          this.store.dispatch([
            new SetIsLoaderEnable(false),
            new SetToasterSuccessMsg('Manager has been archived.'),
          ]);
          this.getManager();
        } else {
          this.store.dispatch([
            new SetIsLoaderEnable(false),
            new SetToasterErrorMsg(),
          ]);
        }
      });
  }
}
