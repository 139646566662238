export enum PodActionTypes {
  ADD_ONE = '[Pods] Add One',
  UPDATE_ONE = '[Pods] Update One',
  DELETE_ONE = '[Pods] Delete One',
  GET_ALL = '[Pods] Get All',
}

//Read
export class GetPods {
  static readonly type = '[Pods] Fetch';
  constructor(public page: number, public perPage: number) {}
}

export class UpdatePod {
  static readonly type = '[Pod] Update';
  constructor(public data: any, public id: number, public page: number) {}
}

export class CreatePod {
  static readonly type = '[Pod] Create';
  constructor(public data: any, public page: number) {}
}
