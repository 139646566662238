<div bsModal #customPopup="bs-modal" class="modal fade" id="customPopup" tabindex="-1" role="dialog"
    aria-labelledby="modalCenterTitle" aria-hidden="true">
    <div id="{{customPopupConfig.modalId}}" class="modal-dialog modal-dialog-centered {{customPopupConfig.class}}"
        role="document">
        <div class="modal-content transparent-modal">
            <div class="modal-body">
                <div class="modal-title d-flex">
                    <h3>
                        {{customPopupConfig.title}}
                    </h3>
                    <span *ngIf="customPopupConfig.closeEnable" class="close" (click)="close()"><img
                            src="assets/images/remove-popup.svg" alt=""></span>
                </div>
                <p *ngIf="customPopupConfig.content" [innerHTML]="customPopupConfig.content"></p>
                <ng-content></ng-content>
                <div class="form-group btn-block btn-block d-flex justify-content-end">
                    <button class="btn {{button.class}}" id="{{button.id}}" type="button"
                        (click)="modalFunc(customPopupConfig.modalId,button.id)"
                        *ngFor="let button of customPopupConfig.buttons">
                        {{button.name}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>