import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AddEditPodComponent } from './add-edit-pod-modal/add-edit-pod.component';

@Component({
  selector: 'app-pod-detail',
  templateUrl: './pod-detail.component.html',
  styleUrls: ['./pod-detail.component.scss'],
})
export class PodDetailComponent implements OnInit {
  @Input('pod') pod: any = {};
  @Input('userType') userType;
  // @ViewChild('editSquadForm') editSquadForm: AddEditPodComponent;
  editMode = false;

  @Output() edit: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  onEdit(isEditMode) {
    this.editMode = isEditMode;
    // this.editSquadForm.addEditSquadForm.showHideModal(true);
    this.edit.emit(this.pod);
  }

  getName(fname, lname): string {
    return this.getFullName(fname, lname);
  }

  getFullName(fname, lname): string {
    let val = '';
    if (!fname && !lname) {
      val = '';
    } else {
      if (fname && lname) {
        val = fname[0] + lname[0];
      } else {
        if (fname) {
          val = fname[0];
        } else if (lname) {
          val = lname[0];
        }
      }
      if (val == undefined) {
        val = '';
      }
    }
    return val;
  }
}
