import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CustomModalComponent } from '../shared/component/custom-modal/custom-modal.component';
import { ManagerFormComponent } from '../manager-form/manager-form.component';
import { Store } from '@ngxs/store';
import { SetIsLoaderEnable } from '@shared/store/loader/loader.action';
import { UpdateManager } from '@shared/store/manager/manager.action';
import {
  SetToasterErrorMsg,
  SetToasterSuccessMsg,
} from '@shared/store/toaster/toaster.action';

@Component({
  selector: 'app-manager-edit',
  templateUrl: './manager-edit.component.html',
  styleUrls: ['./manager-edit.component.scss'],
})
export class ManagerEditComponent implements OnInit {
  @Input('editUser') editUser: any;
  @Output('managerUpdatedData') managerUpdatedData = new EventEmitter();
  @Output('updateManagerCall') updateManagerCall = new EventEmitter();
  @ViewChild(CustomModalComponent, { static: true })
  modalPopup: CustomModalComponent;
  @ViewChild('managerForm', { static: true })
  managerFormComponent: ManagerFormComponent;
  saveOrCancel: 'save' | 'cancel' = 'cancel';

  constructor(private store: Store) {}

  ngOnInit(): void {}

  openModal() {
    this.saveOrCancel = 'save';
    this.modalPopup.showHideModal(true);
  }

  submitEditManager(data) {
    this.saveOrCancel = 'save';
    this.store
      .dispatch([
        new SetIsLoaderEnable(true),
        new UpdateManager(data, this.editUser.id),
      ])
      .subscribe(
        () => {
          this.store.dispatch([
            new SetIsLoaderEnable(false),
            new SetToasterSuccessMsg('Details have been updated.'),
          ]);
          this.resetManager();
        },
        (error) => {
          this.store.dispatch([
            new SetIsLoaderEnable(false),
            new SetToasterErrorMsg(),
          ]);
        }
      );
  }

  public resetManager() {
    this.saveOrCancel = 'cancel';
    this.managerFormComponent.resetManagerForm();
    this.updateManagerCall.emit(false);
    this.modalPopup.customModal.hide();
  }
}
