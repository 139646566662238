import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from '../../services/data.service';

@Pipe({
  name: 'designationMapValue',
})
export class DesignationMapValuePipe implements PipeTransform {
  constructor(public dataService: DataService) {}

  transform(designationKey: string, ...args: unknown[]): unknown {
    if (
      this.dataService.user.designation_map &&
      this.dataService.user.designation_map[designationKey]
    ) {
      return this.dataService.user.designation_map[designationKey];
    } else {
      return designationKey;
    }
  }
}
