export class PodModel {
  data: any;
  //   last_name: string;
  title: string;
  //   done: boolean;
  //   description: string;
  loading: boolean;
  storeProjects: any;
  podList: any;
}
