import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Select } from '@ngxs/store';
import { ManagerSelector } from '@shared/store/manager/manager.selector';
import { PodModel } from '@shared/store/pod/pod.model';
import { PodSelector } from '@shared/store/pod/pod.selector';
import { Observable } from 'rxjs';
import { CommonService } from '../services/common.service';
import { DataService } from '../services/data.service';
import { ManagerService } from '../services/manager.service';
import { UAParser } from 'ua-parser-js';
const parser = new UAParser();
const userBrowser = parser.getBrowser();
const userDevice = parser.getDevice();

@Component({
  selector: 'app-manager-nav',
  templateUrl: './manager-nav.component.html',
  styleUrls: ['./manager-nav.component.scss'],
})
export class ManagerNavComponent implements OnInit {
  activeStatus: string = 'proManager-tab';
  @Select(ManagerSelector.getManagerListCount)
  managerPageCount$: Observable<number>;

  @Select(ManagerSelector.getManagerFullResponse)
  getManagerFullResponse$: Observable<any>;

  @Select(PodSelector.getPodList)
  podListCount$: Observable<PodModel[]>;

  proManagerCount = 0;
  archivedManagerCount = 0;

  @Output('managerTabChange') managerTabChange: EventEmitter<string> =
    new EventEmitter();
  constructor(
    public managerService: ManagerService,
    private commonService: CommonService,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.getManagerFullResponse$.subscribe((data) => {
      if (data && data?.groups?.archived_for_partner?.buckets) {
        this.proManagerCount = this.getManagerCountFromGroup(data, 'false');
        this.archivedManagerCount = this.getManagerCountFromGroup(data, 'true');
      } else if (data && data.apiType === 'add') {
        this.proManagerCount = this.proManagerCount + 1;
      }
    });
  }

  public clickManagerTab(status: string) {
    this.activeStatus = status;
    this.managerService.managerTabStatus = status;
    this.managerTabChange.emit(status);
    this.commonService.trackSegmentEvent('squad_clicked', {
      user_designation: this.dataService.getUserData().designation,
      user_id: this.dataService.getUserData().id,
      user_browser: userBrowser,
      user_device: userDevice,
    });
  }

  getManagerCountFromGroup(data, type) {
    const partnerData: any = JSON.parse(localStorage.getItem('partnerData'));
    const partnerId: any = partnerData ? partnerData.id : null;
    if (
      data.groups &&
      data.groups.archived_for_partner &&
      data.groups.archived_for_partner.buckets
    ) {
      if (data.groups.archived_for_partner.buckets.length === 0) {
        return 0;
      } else {
        let bucket = data.groups.archived_for_partner.buckets.filter(
          (bucket) => {
            return bucket.key === partnerId + '_' + type;
          }
        );
        return bucket && bucket.length > 0 ? bucket[0].count : 0;
      }
    }
    return 0;
  }
}
