export class Constants {
  static apiEndPoints = {
    getAllManager: 'admins',
    getPods: 'pods',
    inviteManager: 'admins/invite',
    timezone: 'timezones',
    reInviteUser: 'admins/reinvite_user',
    archiveManager: 'admins/[manager_id]/archive?value=',
  };
}

export class ManagerConstants {
  static departments = [
    { d_name: 'Engineering', d_value: 'engineering' },
    { d_name: 'Finance', d_value: 'finance' },
    { d_name: 'Human Resources', d_value: 'human_resources' },
    { d_name: 'IT', d_value: 'it' },
    { d_name: 'Sales', d_value: 'sales' },
    { d_name: 'Marketing', d_value: 'marketing' },
  ];

  static offices = [
    { o_name: 'Gurgaon', o_value: 'gurgaon' },
    { o_name: 'San Francisco', o_value: 'san_fransisco' },
    { o_name: 'Los Angeles', o_value: 'los_angeles' },
    { o_name: 'London', o_value: 'london' },
    { o_name: 'Mauritius', o_value: 'mauritius' },
    { o_name: 'Amsterdam', o_value: 'amsterdam' },
    { o_name: 'Germany', o_value: 'germany' },
    { o_name: 'Kuala Lumpur', o_value: 'kuala_lumpur' },
    { o_name: 'Dubai', o_value: 'dubai' },
    { o_name: 'Singapore', o_value: 'singapore' },
    { o_name: 'Salt Lake City', o_value: 'salt_lake_city' },
  ];
}
