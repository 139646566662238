import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select } from '@ngxs/store';
import { ManagerConstants } from '@shared/constants';
import { LoaderSelector } from '@shared/store/loader/loader.selector';
import { filter, Observable } from 'rxjs';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-manager-form',
  templateUrl: './manager-form.component.html',
  styleUrls: ['./manager-form.component.scss'],
})
export class ManagerFormComponent implements OnInit {
  managerForm: FormGroup;
  designations: any[];
  timezoneConfig = {
    id: 'time_zone',
    componentName: 'ManagerFormComponent',
    saveOrCancelChanges: 'save', // contains on 'save' or 'cancel',
    selectedData: '',
    disabled: true,
    isRequired: true,
    isFormGrpClassEnable: false,
    isDropdownOnTop: true,
  };
  departments = ManagerConstants.departments;
  offices = ManagerConstants.offices;
  cCodeValue = '';
  errorMessage = '';

  @ViewChild('countryCodeValue', { static: true }) countryCodeValue;
  @Input('manager') manager;
  @Output('onSubmit') onSubmit = new EventEmitter();
  @Input('saveOrCancel') saveOrCancel: 'save' | 'cancel';
  @Select(LoaderSelector.isAuthTokenUpdated)
  authTokenUpdated: Observable<boolean>;
  constructor(
    protected formBuilder: FormBuilder,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.authTokenUpdated
      .pipe(filter((x) => x != undefined || x != null))
      .subscribe((isupdated: boolean) => {
        if (isupdated) {
          let designationList = [];
          if (
            this.dataService?.user?.all_designations &&
            Array.isArray(this.dataService.user.all_designations)
          ) {
            designationList = [...this.dataService.user.all_designations];
          }
          this.designations = [
            ...designationList,
            { key: 'Others', value: 'Others' },
          ];
        }
      });
  }

  ngOnChanges() {
    this.timezoneConfig.saveOrCancelChanges = this.saveOrCancel;
    this.timezoneConfig.selectedData = '';
    this.timezoneConfig.disabled = true;
    this.timezoneConfig = Object.assign({}, this.timezoneConfig);
    if (this.manager) {
      this.setValues();
    }
  }

  initializeForm() {
    this.managerForm = this.formBuilder.group({
      first_name: ['', [Validators.required, Validators.minLength(3)]],
      last_name: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
      designation: ['', [Validators.required]],
      defaultDesignation: [''],
      department: ['', [Validators.required]],
      office: ['', [Validators.required]],
    });
  }

  allowAlphabets(event: any) {
    const pattern = /^[A-Za-z0-9_@.#$=!%^)(\]:\*;\?\/\,}{'\|<>\[&\+-]*$/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  public onlyNumber(evt) {
    evt = evt ? evt : window.event;
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode < 48 || charCode > 57) {
      return false;
    }
    return true;
  }

  get firstName() {
    return this.managerForm.get('first_name');
  }

  get lastName() {
    return this.managerForm.get('last_name');
  }

  get email() {
    return this.managerForm.get('email');
  }

  get phone() {
    return this.managerForm.get('phone');
  }

  get designation() {
    return this.managerForm.get('designation');
  }

  get defaultDesignation() {
    return this.managerForm.get('defaultDesignation');
  }

  get department() {
    return this.managerForm.get('department');
  }

  get office() {
    return this.managerForm.get('office');
  }

  get values() {
    return this.managerForm.value;
  }

  get valid(): boolean {
    if (this.countryCodeValue.value === '') {
      setTimeout(() => {
        this.countryCodeValue.value = '+91';
      });
    }
    if (
      this.managerForm.controls.first_name.valid &&
      this.managerForm.controls.last_name.valid &&
      this.countryCodeValue.value &&
      this.managerForm.controls.phone.valid
    ) {
      return this.managerForm.valid;
    }
  }

  resetManagerForm() {
    // this.managerService.duplicateErrorMsg = '';
    this.errorMessage = '';
    this.cCodeValue = '';
    this.managerForm.reset();
  }

  desgChange() {
    if (this.managerForm.value.designation === 'Others' && this.manager) {
      this.manager.defaultDesignation = '';
      this.errorMessage = '';
    }
  }

  onOfficeChange() {
    if (
      this.office.value &&
      this.office.value === 'dubai' &&
      this.phone?.value?.length > 9
    ) {
      this.phone.setValue('');
    }
    this.updatePhoneNumberValidations();
  }

  countryCodeChange() {
    if (
      this.countryCodeValue.value &&
      (this.countryCodeValue.value === '+971' ||
        this.countryCodeValue.value === '971') &&
      this.phone?.value?.length > 9
    ) {
      this.phone.setValue('');
    }
    this.updatePhoneNumberValidations();
  }

  updatePhoneNumberValidations() {
    const office = this.office.value;
    if (
      office === 'dubai' ||
      this.countryCodeValue.value === '+971' ||
      this.countryCodeValue.value === '971'
    ) {
      this.phone.setValidators([]);
      this.phone.setValidators([
        Validators.required,
        Validators.pattern('[0-9]{9}'),
      ]);
    } else {
      this.phone.setValidators([]);
      this.phone.setValidators([
        Validators.required,
        Validators.pattern('[0-9]{10}'),
      ]);
    }
    this.office.updateValueAndValidity();
    this.managerForm.updateValueAndValidity();
  }

  getSelectedTimezone(timezone) {
    this.timezoneConfig.selectedData = timezone;
  }

  formSubmit() {
    this.values.email = this.values.email.toLowerCase().trim();
    this.values.first_name = this.values.first_name.trim();
    this.values.last_name = this.values.last_name.trim();
    if (this.designation.value === 'Others' && !this.defaultDesignation.value) {
      this.errorMessage = 'Please Specify designation';
      return;
    }
    if (this.designation.value === 'Others') {
      this.managerForm.value.designation = this.defaultDesignation.value;
    }
    this.values.role = 'pm';
    this.values.country_code = this.countryCodeValue.value;
    this.values.timezone = this.timezoneConfig.selectedData;
    const manager = { user: this.values };
    this.errorMessage = '';
    this.onSubmit.emit(manager);
  }

  setValues(type?) {
    if (!this.managerForm) {
      return;
    }
    if (type === 'cancel') {
      this.managerForm.value.designation = '';
      this.manager.defaultDesignation = '';
      this.errorMessage = '';
      this.cCodeValue = '';
      return;
    }
    let cCode;
    this.cCodeValue = '';
    if (this.manager.country_code && this.manager.country_code.includes('+')) {
      cCode = this.manager.country_code.split('+')[1];
    } else {
      cCode = this.manager.country_code;
    }
    if (this.countryCodeValue.countries) {
      for (let i = 0; i < this.countryCodeValue.countries.length; i++) {
        if (cCode === this.countryCodeValue.countries[i].dialCode) {
          this.cCodeValue = this.countryCodeValue.countries[i].countryCode;
          this.countryCodeValue.value =
            '+' + this.countryCodeValue.countries[i].dialCode;
        }
      }
    }
    this.managerForm.value.designation = '';
    this.manager.defaultDesignation = '';
    this.errorMessage = '';
    if (this.designations && this.designations.length) {
      for (let i = 0; i < this.designations.length; i++) {
        if (this.manager.designation === this.designations[i].key) {
          this.managerForm.value.designation = this.manager.designation;
        }
      }
    }
    if (this.manager.designation && !this.managerForm.value.designation) {
      this.manager.defaultDesignation = this.manager.designation;
      this.manager.designation = 'Others';
    }
    if (this.manager.country_code && !this.manager.country_code.includes('+')) {
      this.manager.country_code = '+' + this.manager.country_code;
    }

    // set dubai phone number validation
    if (
      this.manager.office === 'dubai' ||
      this.manager.country_code === '971' ||
      this.manager.country_code === '+971'
    ) {
      this.updatePhoneNumberValidations();
    }
    this.timezoneConfig.selectedData = this.manager.timezone;
    this.timezoneConfig.disabled = true;
    this.timezoneConfig = Object.assign({}, this.timezoneConfig);
    this.managerForm.patchValue(this.manager);
  }
}
