import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
  selector: 'app-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss'],
})
// This modal popup is used only for CRUD. This contains only the modal. The data should be passed
export class CustomModalComponent implements OnInit {
  @ViewChild('customModal', { static: false }) customModal: ModalDirective;
  @Output() modalClosed = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  showHideModal(val): void {
    val ? this.customModal.show() : this.customModal.hide();
  }

  ngAfterViewInit() {
    this.customModal.onHidden.subscribe(() => {
      this.modalClosed.emit({ modalClosed: true});
    });
  }
}
