import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DesignationMapValuePipe } from './custom-pipes/designation-map-value.pipe';
import { CustomPopupComponent } from './component/custom-popup/custom-popup.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { LoaderComponent } from './component/loader/loader.component';
import { CustomModalComponent } from './component/custom-modal/custom-modal.component';
import { TimeZoneComponent } from './component/time-zone/time-zone.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { timezoneTextOnly } from './custom-pipes/timezoneTextOnly.pipe';
import { ToasterComponent } from './component/toaster/toaster.component';
import { BrowserModule } from '@angular/platform-browser';
//services

//components
@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ModalModule.forRoot(),
    NgMultiSelectDropDownModule,
  ],
  declarations: [
    DesignationMapValuePipe,
    CustomPopupComponent,
    LoaderComponent,
    CustomModalComponent,
    TimeZoneComponent,
    timezoneTextOnly,
    ToasterComponent,
  ],

  exports: [
    DesignationMapValuePipe,
    CustomPopupComponent,
    LoaderComponent,
    CustomModalComponent,
    TimeZoneComponent,
    timezoneTextOnly,
    ToasterComponent,
  ],
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
