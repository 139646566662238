import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManagerDetailComponent } from './manager-detail/manager-detail.component';
import { ManagerHomeComponent } from './manager-home/manager-home.component';

const routes: Routes = [
  {
    path: '',
    component: ManagerHomeComponent,
  },
  { path: ':id/manager-detail', component: ManagerDetailComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
