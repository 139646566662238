import { Selector } from '@ngxs/store';
import { ToasterModel } from './toaster.model';
import { ToasterState } from './toaster.state';

export class ToasterSelector {
  @Selector([ToasterState])
  static getToastMessage(state: ToasterModel) {
    return state;
  }
}
