import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SetIsLoaderEnable } from '@shared/store/loader/loader.action';
import { ReInviteUser } from '@shared/store/manager/manager.action';
import {
  SetToasterErrorMsg,
  SetToasterSuccessMsg,
} from '@shared/store/toaster/toaster.action';
import { Observable } from 'rxjs';
import { ManagerService } from '../services/manager.service';

@Component({
  selector: 'app-manager-item',
  templateUrl: './manager-item.component.html',
  styleUrls: ['./manager-item.component.scss'],
})
export class ManagerItemComponent implements OnInit {
  managers = [];
  isInviteEnable;
  throttle = 200;
  scrollDistance = 1;
  page = 1;
  isShowPaginationLoader = false;

  @Input('user') user;
  @Input('userType') userType;
  @ViewChild('scrollStart') scrollStart;
  @Output('editManagerDetail') editManagerDetail = new EventEmitter();
  constructor(
    private managerService: ManagerService,
    public router: Router,
    private store: Store
  ) {}

  ngOnInit(): void {}

  goToManagerDetail(id) {
    if (this.userType === 'pm_lead') {
      this.router.navigate([`/${id}/manager-detail`]);
    }
  }

  editManager(manager) {
    this.managerService.managerID = manager.id;
    this.editManagerDetail.emit(manager);
  }

  sendReInvite(manager) {
    this.isInviteEnable = true;
    this.managerService.managerID = manager.id;
    this.store
      .dispatch([new SetIsLoaderEnable(true), new ReInviteUser()])
      .subscribe(
        (data) => {
          this.store.dispatch([
            new SetIsLoaderEnable(false),
            new SetToasterSuccessMsg('Invitation email has been sent.'),
          ]);
        },
        (error) => {
          this.store.dispatch(new SetToasterErrorMsg());
        }
      );
  }
}
