<div id="time_zone" [class]="{'form-group':timezoneConfig.isFormGrpClassEnable}">
    <label>Timezone
        <em *ngIf="timezoneConfig.isRequired">*</em>
    </label>
    <div [class.editable-field]="timezoneConfig.disabled">
        <ng-multiselect-dropdown [ngClass]="{'on-top':timezoneConfig.isDropdownOnTop}" id="{{timezoneConfig.id}}"
            *ngIf="getUserObject()" name="timezone" [(ngModel)]="selectedItem" [data]="timezones"
            [settings]="dropdownSettings" (onSelect)="onItemSelect($event)" [disabled]="!timezoneConfig.disabled"
            (onDropDownClose)="dropdownInvalid()" (click)="dropDownSelect = true" #timezone="ngModel">
        </ng-multiselect-dropdown>
    </div>
    <div class="invalid-feedback">{{timezoneErrorMsg}} </div>
</div>