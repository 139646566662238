import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ManagerNavComponent } from './manager-nav/manager-nav.component';
import { ManagerHomeComponent } from './manager-home/manager-home.component';
import { ManagerListComponent } from './manager-list/manager-list.component';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { ManagerService } from './services/manager.service';
import { HttpClientModule } from '@angular/common/http';
import { DataService } from './services/data.service';
import { ManagerItemComponent } from './manager-item/manager-item.component';
import { SharedModule } from '@shared/shared.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ManagerAddComponent } from './manager-add/manager-add.component';
import { PodDetailComponent } from './pod-detail/pod-detail.component';
import { PodService } from './services/pod.service';
import { storeState } from '@shared/store/store';
import { CustomRouterStateSerializer } from '@shared/store/router/router-state.serializer';
import {
  NgxsRouterPluginModule,
  RouterStateSerializer,
} from '@ngxs/router-plugin';
import { ManagerFormComponent } from './manager-form/manager-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import { AddEditPodComponent } from './pod-detail/add-edit-pod-modal/add-edit-pod.component';
import { ManagerEditComponent } from './manager-edit/manager-edit.component';
import { ManagerDetailComponent } from './manager-detail/manager-detail.component';
import { Angulartics2Module } from 'angulartics2';

@NgModule({
  declarations: [
    AppComponent,
    ManagerNavComponent,
    ManagerHomeComponent,
    ManagerListComponent,
    ManagerItemComponent,
    ManagerAddComponent,
    PodDetailComponent,
    ManagerFormComponent,
    AddEditPodComponent,
    ManagerEditComponent,
    ManagerDetailComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxsModule.forRoot(storeState),
    NgxsRouterPluginModule.forRoot(),
    // NgxsLoggerPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    SharedModule,
    InfiniteScrollModule,
    FormsModule,
    ReactiveFormsModule,
    InternationalPhoneNumberModule,
    Angulartics2Module.forRoot(),
  ],
  providers: [
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
