// Inside the root 'store.ts' file of our store, eg - store/store.ts
import { LoaderState } from './loader/loader.state';
import { ManagerState } from './manager/manager.state';
import { PodState } from './pod/pod.state';
import { ToasterState } from './toaster/toaster.state';

// Still allow other modules to take what they need, eg action & selectors
export * from './manager/manager';
export * from './pod/pod';
export * from './loader/loader';
export * from './toaster/toaster';
// rolls up our states into one const
export const storeState = [ManagerState, PodState, LoaderState, ToasterState];
