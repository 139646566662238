<app-custom-modal>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content transparent-modal">
            <div class="modal-body">
                <h2 class="noPadding">Edit Manager </h2>
                <!-- <span *ngIf="error">{{error}}</span> -->
                <app-manager-form #managerForm [manager]="editUser" [saveOrCancel]="saveOrCancel"
                    (onSubmit)="submitEditManager($event)">
                    <div class="form-group d-flex justify-content-end btn-block">
                        <input class="btn cancel-btn" type="button" name="" value="Cancel" (click)="resetManager()">
                        <input class="btn add-btn" type="submit" name="" value="Save Changes"
                            [disabled]="!managerForm.valid">
                    </div>
                </app-manager-form>
            </div>
        </div>
    </div>
</app-custom-modal>