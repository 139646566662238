<div #scrollStart infiniteScroll [infiniteScrollDistance]="scrollDistance" [infiniteScrollThrottle]="throttle"
  (scrolled)="onScrollDown()">
  <app-manager-nav (managerTabChange)="managerTabChange($event)"></app-manager-nav>

  <div *ngIf="managerTab != 'pod-tab'" class="search-block">
    <div class="search-filter">
      <input type="submit" name="" value="" />
      <input type="text" name="" [(ngModel)]="searchText" placeholder="Search" (keyup)="searchChange($event)" />
    </div>
  </div>

  <div *ngIf="isDataLoaded" class="row manager-list">
    <!-- Add new Manager -->
    <ng-container *ngIf="managerTab !== 'pod-tab'">
      <div *ngIf="managerTab === 'proManager-tab'" class="col-md-6 col-lg-4 col-xxl-3 manager-col">
        <div class="add-new-manager" (click)="addManager()">
          <div class="add-new-inner">
            <img src="../../assets/images/add-icon.png" alt="" />
            <p>Add New Manager</p>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- End - Add new Manager -->

    <ng-container *ngIf="managerTab !== 'pod-tab'">
      <ng-container *ngIf="managerDataList$ | async; let managerData">
        <!-- Display list of Manager -->
        <div class="col-md-6 col-lg-4 col-xxl-3 manager-col" *ngFor="let user of managerData; let i = index">
          <app-manager-item [user]="user" [userType]="dataService?.user.role"
            (editManagerDetail)="editManagerInList($event)"></app-manager-item>
        </div>
        <!-- End - Display list of Manager -->
      </ng-container>

      <!-- Manager Edit -->
      <app-manager-edit (updateManagerCall)="updateManagerCall($event)" [editUser]="editManager"
        (managerUpdatedData)="managerUpdatedData($event)"></app-manager-edit>
      <!-- End - Manager Edit -->
    </ng-container>

    <ng-container *ngIf="managerTab === 'pod-tab'">
      <ng-container *ngIf="(podIsLoading$ | async); let isLoading">
        <app-loader class="loader-center" [isPaginationLoaderShow]="isLoading"></app-loader>
      </ng-container>
      <!-- Add new Squad -->
      <div *ngIf="(podData$ | async)?.length != 0" class=" col-md-6 col-lg-4 col-xxl-3 pod-tab-col">
        <div class="add-new-pod" (click)="addPodMember(false)">
          <div class="add-new-inner">
            <img src="assets/images/add-icon.png" />
            <p class="">Add Squad</p>
          </div>
        </div>
      </div>
      <!-- End - Add new Squad -->

      <ng-container *ngIf="podData$ | async; let podData">
        <!-- Display list of Squad -->
        <div class="col-md-6 col-lg-4 col-xxl-3 manager-col pod-tab-col" *ngFor="let pod of podData; let i = index">
          <app-pod-detail [pod]="pod" [userType]="dataService?.user.role" (edit)="onPodEdit($event)">
          </app-pod-detail>
        </div>
        <!-- End - Display list of Squad -->
      </ng-container>
    </ng-container>
    <ng-container *ngIf="managerLoader$ | async; let isLoading">
      <ng-container *ngIf="
          managerTab !== 'pod-tab' && (managerDataList$ | async)?.length != 0
        ">
        <!-- Common loader for both manager -->
        <app-loader [isPaginationLoaderShow]="isLoading"></app-loader>
        <!-- End - Common loader for both manager -->
      </ng-container>
    </ng-container>
  </div>
</div>

<app-manager-add></app-manager-add>
<add-edit-pod-comp #addSquadForm [selectedSquad]="editSelectedPod" [editMode]="editMode"></add-edit-pod-comp>