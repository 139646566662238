import { Action, Selector, State, StateContext } from '@ngxs/store';
import { PodModel } from './pod.model';
import { PodState } from './pod.state';

export class PodSelector {
  @Selector([PodState])
  static getPodList(state: PodModel) {
    return state?.podList ? state?.podList : [];
  }

  @Selector([PodState])
  static getPodFullResponse(state: PodModel) {
    return state.data;
  }

  @Selector([PodState])
  static getPodStoreProjects(state: PodModel) {
    return state.storeProjects.store_projects;
  }

  @Selector([PodState])
  static isLoading(state: PodModel) {
    return state.loading;
  }
}
