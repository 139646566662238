import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { IsAuthTokeUpdated } from '@shared/store/loader/loader.action';
import { LoaderSelector } from '@shared/store/loader/loader.selector';
import { ToasterModel } from '@shared/store/toaster/toaster.model';
import { ToasterSelector } from '@shared/store/toaster/toaster.selector';
import { filter, Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, DoCheck, OnDestroy {
  title = 'b360-manager-dashboard';
  @Select(LoaderSelector.getIsLoading) isLoading$: Observable<boolean>;
  @Select(ToasterSelector.getToastMessage)
  showToasterMsg$: Observable<ToasterModel>;
  $eventBus: any;
  onEventHandler: any;

  constructor(public cdr: ChangeDetectorRef, private store: Store) {
    this.onEventHandler = this.onReceiveEventHandler.bind(this);
  }

  ngOnInit() {
    if (!this.$eventBus) {
      this.$eventBus = window.addEventListener(
        'message',
        this.onEventHandler,
        false
      );
    }

    this.isLoading$
      .pipe(filter((x) => x != undefined || x != null))
      .subscribe((isLoadState: boolean) => {
        if (isLoadState) {
          window.parent.postMessage('show-loader', '*');
        } else {
          window.parent.postMessage('hide-loader', '*');
        }
      });

    // window.onmessage = function (e) {
    //   console.log('onmessage = ', e.data);
    //   let payload = JSON.parse(e.data);
    //   localStorage.setItem(payload.key, payload.data);
    // };
  }

  onReceiveEventHandler(e: any) {
    if (e.data && e.data.type !== 'webpackOk') {
      let payload = JSON.parse(e.data);
      localStorage.setItem(payload.key, payload.data);
      localStorage.setItem(
        payload.userKey,
        JSON.stringify(JSON.parse(payload.userData))
      );
      localStorage.setItem(
        payload.partnerKey,
        JSON.stringify(JSON.parse(payload.partnerData))
      );
      this.store.dispatch(new IsAuthTokeUpdated(true));
    }
  }

  ngDoCheck() {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {}
}
