import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { CustomModalComponent } from '@shared/component/custom-modal/custom-modal.component';
import { SetIsLoaderEnable } from '@shared/store/loader/loader.action';
import { GetAllManager } from '@shared/store/manager/manager.action';
import { ManagerModel } from '@shared/store/manager/manager.model';
import { ManagerSelector } from '@shared/store/manager/manager.selector';
import { CreatePod, UpdatePod } from '@shared/store/pod/pod.action';
import { PodSelector } from '@shared/store/pod/pod.selector';
import { SetToasterErrorMsg } from '@shared/store/toaster/toaster.action';
import { Observable, Subscription } from 'rxjs';
import { debounce } from 'src/app/utility/utility.functions';

@Component({
  selector: 'add-edit-pod-comp',
  templateUrl: './add-edit-pod.component.html',
  styleUrls: ['../pod-detail.component.scss', './add-edit-pod.component.scss'],
})
export class AddEditPodComponent implements OnInit, OnChanges {
  @Input('selectedSquad') selectedSquad: any = {
    users: [],
  };
  @Input('editMode') editMode;
  @ViewChild('addEditSquadForm') addEditSquadForm: CustomModalComponent;
  @ViewChild('tagInput', { static: false }) tagInput: ElementRef;
  podName: string = '';
  isStorePod = false;
  disableSave = true;
  searchText = '';
  showManager = false;
  page = 1;
  itemPerPage = 15;
  managers = [];
  searchedUsersList = [];
  @Select(ManagerSelector.getManagerList) managerData$: Observable<
    ManagerModel[]
  >;
  @Select(ManagerSelector.getSearchedUsersList) searchedUsersList$: Observable<
    ManagerModel[]
  >;
  @Select(PodSelector.getPodStoreProjects) podStoreProjects$: Observable<any>;
  isShowPaginationLoader = false;
  isSearchLoader = false;
  managerSubscription: Subscription;
  searchedUserSubscription: Subscription;
  searchChange = debounce((event) => {
    this.onKeydownEvent(event);
  });
  InputSquad;

  constructor(public store: Store) {}

  ngOnInit(): void {}

  ngOnChanges() {
    if (this.selectedSquad) {
      this.InputSquad = JSON.parse(JSON.stringify(this.selectedSquad));
      if (!this.editMode) {
        this.resetPod();
      }
      this.podName = this.InputSquad.name
        ? this.InputSquad.name.replace(/ Squad/gi, '')
        : this.podName;
      this.isStorePod = this.InputSquad.is_store_pod
        ? this.InputSquad.is_store_pod
        : this.isStorePod;
      this.InputSquad.users
        ? this.InputSquad.users.forEach((user) => {
            user['checked'] = false;
          })
        : [];
    }

    this.managerSubscription = this.managerData$.subscribe((data) => {
      this.isShowPaginationLoader = false;
      this.managers = [...data];
      this.markChecked(this.managers, false);
    });
  }

  markChecked(data, searched = false) {
    // this part of the code seems incorrect, please review again
    if (
      searched &&
      this.InputSquad &&
      this.InputSquad.users &&
      this.InputSquad.users.length
    ) {
      data.forEach((item) => {
        this.InputSquad.users.forEach((user) => {
          if (item.id == user.id && this.editMode) {
            item['checked'] = true;
            return;
          }
        });
      });
    } else {
      data.forEach((item) => {
        item['checked'] = false;
      });
    }
  }

  modalClosed(event) {
    this.savePodMembers();
  }

  popupHide() {
    this.addEditSquadForm.showHideModal(false);
    this.disableSave = true;
    this.savePodMembers();
  }

  removeMember(event, member) {
    let index = this.InputSquad.users.findIndex((item) => {
      return item.id == member.id;
    });
    if (index > -1) {
      if (this.editMode) {
        this.disableSave = false;
      }
      this.InputSquad.users.splice(index, 1);
      let man_index = this.managers.findIndex((item) => {
        return item.id == member.id;
      });
      if (man_index > -1) {
        this.managers[man_index].checked = false;
      }
    }
    event.stopPropagation();
  }

  tagInputClick() {
    this.tagInput.nativeElement.focus();
    this.showManager = true;
  }

  onSearchChange() {
    if (this.editMode) {
      this.disableSave = false;
    }
  }

  savePodMembers() {
    this.showManager = false;
    this.tagInput.nativeElement.textContent = '';
    this.searchText = '';
  }

  createPod() {
    // service call for edit or create pod or manager, should dispatch an action
    if (this.podName && this.InputSquad.users.length > 0) {
      let pod_ids = [];
      this.InputSquad.users.forEach((item) => {
        pod_ids.push(item.id);
      });
      let podData = {
        pod: {
          name: this.podName + ' Squad',
          is_store_pod: this.isStorePod,
        },
        pod_users_ids: pod_ids,
      };
      if (this.editMode && this.InputSquad.id) {
        this.store.dispatch(
          new UpdatePod(podData, this.InputSquad.id, this.page)
        );
      } else {
        this.store
          .dispatch([
            new CreatePod(podData, this.page),
            new SetIsLoaderEnable(true),
          ])
          .subscribe(
            (data) => {
              console.log('data = ', data);
            },
            (error) => {
              console.log('error = ', error);
              this.store.dispatch([
                new SetIsLoaderEnable(false),
                new SetToasterErrorMsg(
                  error?.error?.name ? error?.error?.name : ''
                ),
              ]);
            }
          );
      }
      this.popupHide();
    }
  }

  toggleStorePod(event) {
    if (this.editMode) {
      this.disableSave = false;
    }
    this.isStorePod = !this.isStorePod;
    event.stopPropagation();
  }

  cursorPosition() {
    let pos = this.tagInput.nativeElement.textContent.length;
    if (pos > 0) {
      let tag = this.tagInput.nativeElement;
      let setpos = document.createRange();
      let set = window.getSelection();
      setpos.setStart(tag.childNodes[0], pos);
      setpos.collapse(true);
      set.removeAllRanges();
      set.addRange(setpos);
    }
  }

  onKeydownEvent(event) {
    if (event.keyCode != 9 && event.keyCode != 91) {
      this.searchText = this.tagInput.nativeElement.textContent;
      this.getManagers();
      this.showManager = true;
    }
    this.searchedUserSubscription = this.searchedUsersList$.subscribe(
      (data) => {
        this.isShowPaginationLoader = false;
        this.searchedUsersList = data;
        this.markChecked(this.searchedUsersList, true);
      }
    );
  }

  resetPod() {
    this.InputSquad = [];
    this.tagInput.nativeElement.textContent = '';
    if (this.managerSubscription) {
      this.managerSubscription.unsubscribe();
    }
    this.podName = '';
    this.searchText = '';
    this.managers = [];
    this.showManager = false;
    // this.pod = null;
    this.disableSave = true;
    this.isStorePod = false;
  }

  getManagers(response?) {
    if (response !== 'scroll') {
      this.page = 1;
      this.isShowPaginationLoader = false;
      // this.isSearchLoader = true;
    } else {
      this.isShowPaginationLoader = true;
    }
    this.itemPerPage = 16;
    this.store.dispatch(
      new GetAllManager(
        this.page,
        this.itemPerPage,
        this.searchText,
        'unarchived',
        true
      )
    );
  }

  checkPod(manager) {
    let sel_index;
    let man_index;
    if (this.editMode) {
      this.disableSave = false;
    }
    if (this.InputSquad && this.InputSquad.users) {
      sel_index = this.InputSquad.users.findIndex((item) => {
        return item.id == manager.id;
      });
      man_index = this.managers.findIndex((item) => {
        return item.id == manager.id;
      });
    }
    if (sel_index > -1) {
      if (this.InputSquad && this.InputSquad.users) {
        this.InputSquad.users.splice(sel_index, 1);
      }
      if (man_index > -1) {
        this.managers[man_index].checked = false;
      }
    } else {
      if (this.InputSquad && !this.InputSquad.users) {
        this.InputSquad['users'] = [];
      }
      this.InputSquad['users'].push(manager);
      if (man_index > -1 && this.editMode) {
        this.managers[man_index].checked = true;
      }
    }
  }

  onScrollMember() {
    if (this.page !== null && !this.isShowPaginationLoader) {
      this.getManagers('scroll');
      this.isShowPaginationLoader = true;
    }
  }

  ngOnDestroy() {
    this.managerSubscription ? this.managerSubscription.unsubscribe() : null;
    this.searchedUserSubscription
      ? this.searchedUserSubscription.unsubscribe()
      : null;
  }
}
