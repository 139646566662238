import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CustomModalComponent } from '../shared/component/custom-modal/custom-modal.component';
import { ManagerFormComponent } from '../manager-form/manager-form.component';
import { Store } from '@ngxs/store';
import { AddManager } from '@shared/store/manager/manager.action';
import { SetIsLoaderEnable } from '@shared/store/loader/loader.action';
import {
  SetToasterErrorMsg,
  SetToasterSuccessMsg,
} from '@shared/store/toaster/toaster.action';

@Component({
  selector: 'app-manager-add',
  templateUrl: './manager-add.component.html',
  styleUrls: ['./manager-add.component.scss'],
})
export class ManagerAddComponent implements OnInit {
  @ViewChild(CustomModalComponent, { static: true })
  modalPopup: CustomModalComponent;

  @ViewChild('managerForm', { static: true })
  managerFormComponent: ManagerFormComponent;

  @Output('updateManagerCall')
  updateManagerCall = new EventEmitter();
  saveOrCancel: 'save' | 'cancel';

  constructor(private store: Store) {}

  ngOnInit(): void {}

  openModal() {
    this.saveOrCancel = 'save';
    this.modalPopup.showHideModal(true);
  }

  public submitManager(manager) {
    this.saveOrCancel = 'save';
    this.store
      .dispatch([new SetIsLoaderEnable(true), new AddManager(manager)])
      .subscribe(
        () => {
          this.store.dispatch([
            new SetIsLoaderEnable(false),
            new SetToasterSuccessMsg('Manager has been added.'),
          ]);
          this.resetManager();
        },
        (error) => {
          this.store.dispatch([
            new SetIsLoaderEnable(false),
            new SetToasterErrorMsg(
              error?.error?.error ? error?.error?.error : ''
            ),
          ]);
        }
      );
  }

  public resetManager() {
    this.saveOrCancel = 'cancel';
    this.managerFormComponent.resetManagerForm();
    this.updateManagerCall.emit(false);
    this.modalPopup.customModal.hide();
  }
}
