<router-outlet></router-outlet>

<!-- <ng-container *ngIf="isLoading$ | async; let  isLoading">
    <app-loader [isLoader]="isLoading"></app-loader>
</ng-container> -->

<ng-container *ngIf="showToasterMsg$ | async; let showToasterMsg">
  <ng-container *ngIf="showToasterMsg.toastMessage">
    <app-toaster [showToasterMsg]="true"></app-toaster>
  </ng-container>
</ng-container>
