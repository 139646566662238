<!-- Only for Pagination.-->
<div class="pagination-loader" *ngIf="isPaginationLoaderShow">
    <img src="../../../assets/images/pagination-loader.gif" alt="">
</div>
<!-- End - Only for Pagination.-->

<!-- main loader, user cannot work -->
<div class="initialLoader" *ngIf="isLoader">
    <div class="homeLoader"><img src="../../../assets/images/builder_black.png" /></div>
    <div class="loadingOverlay"></div>
</div>