<div class="header-row">
    <div class="pod-group-name" title="{{pod?.name}}">
        {{pod?.name}}
    </div>
    <div class="edit-pod-name">
        <span *ngIf="pod?.is_store_pod" class="studio-pod-info start-stop-installments">
            <img src="../../assets/images/studio-store.svg" />
            <div class="installment-content-tooltip">
                <p>This Squad is opted for Studio Store</p>
            </div>
        </span>
        <span (click)="onEdit(true)" *ngIf="userType === 'pm_lead'">
            <i aria-hidden="true" class="fa fa-pencil"></i>
        </span>
    </div>
</div>
<div class="pod-member-list">
    <div class="pod-list-heading">Members</div>
    <div class="member-names">
        <ul class="show-pod-list" *ngIf="pod">
            <li *ngFor="let user of pod.users | slice :0:7" class="pod-list-item">
                <!--Here is the repeater list-->
                <div class="pod-circle">
                    <!--Showing short name in card-->
                    {{getName(user['first_name'],user['last_name'] )}}
                </div>
                <div class="pop-info-tooltip">
                    <!--tooltip on hover-->
                    <div class="tooltip-inner-pod">
                        <div class="pod-circle"> {{getName(user['first_name'],user['last_name'] )}}
                        </div>
                        <div class="pod-name"> {{user['first_name'] + ' ' + user['last_name']}} ({{user['designation'] |
                            designationMapValue}})</div>
                    </div>
                </div>
            </li>

            <li *ngIf="pod && pod.users && pod.users.length>8" class="pod-list-item more-list-pod">
                <!--more list showing li-->
                <div class="pod-circle">
                    +{{pod.users.length - 8}}
                </div>
                <div class="pop-info-tooltip">
                    <!--tooltip on hover-->
                    <div class="scroll-tooltip">
                        <div *ngFor="let user of pod.users" class="tooltip-inner-pod">
                            <div class="pod-circle"> {{getName(user.first_name,user.last_name )}} </div>
                            <div class="pod-name">{{user.first_name + ' ' + user.last_name}} ({{user.new_designation}})
                            </div>
                        </div>

                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>

<!-- <add-edit-pod-comp #editSquadForm [selectedSquad]="pod" [editMode]="{mode: editMode}"></add-edit-pod-comp> -->