import { Selector } from '@ngxs/store';
import { LoaderModel } from './loader.model';
import { LoaderState } from './loader.state';

export class LoaderSelector {
  @Selector([LoaderState])
  static getIsLoading(state: LoaderModel) {
    return state.isLoader;
  }

  @Selector([LoaderState])
  static isAuthTokenUpdated(state: LoaderModel) {
    return state.isAuthTokenUpdated;
  }
}
