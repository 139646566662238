<ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item">
        <a class="nav-link blocked-tab-color active" id="proManager-tab" data-toggle="tab" role="tab"
            aria-selected="true" [ngClass]="{'active': activeStatus === 'proManager-tab'}"
            (click)="clickManagerTab('proManager-tab')">All
            <span id="client-count"> ({{proManagerCount}})</span>
        </a>
    </li>
    <li class="nav-item">
        <a class="nav-link blocked-tab-color" id="pod-tab" data-toggle="tab" role="tab" aria-selected="true"
            [ngClass]="{'active': activeStatus === 'pod-tab'}" (click)="clickManagerTab('pod-tab')">Squads
            <span id="client-count">
                ({{ (podListCount$ | async)?.length }})
            </span>
        </a>
    </li>
    <li class="nav-item">
        <a class="nav-link blocked-tab-color" id="archivedManager-tab" data-toggle="tab" role="tab" aria-selected="true"
            [ngClass]="{'active': activeStatus === 'archivedManager-tab'}"
            (click)="clickManagerTab('archivedManager-tab')">Archived
            <span id="client-count">({{archivedManagerCount}})</span>
        </a>
    </li>
    <!-- <li class="nav-item">
      <a class="nav-link blocked-tab-color" id="accManager-tab" data-toggle="tab" role="tab" 
      aria-selected="true">Account Managers</a>
    </li>
    <li class="nav-item">
      <a class="nav-link blocked-tab-color" id="tech-tab" data-toggle="tab" role="tab" 
      aria-selected="true">Tech Experts</a>
    </li> -->
</ul>