import { Injectable } from '@angular/core';
import { Angulartics2Segment } from 'angulartics2';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(public angulartics2Segment: Angulartics2Segment) {}

  public getUserName() {
    const userDetail: any = window.localStorage.getItem('user')
      ? JSON.parse(window.localStorage.getItem('user'))
      : null;
    if (userDetail) {
      return userDetail.first_name + ' ' + userDetail.last_name;
    } else {
      return null;
    }
  }

  /**
   * used to track the event using google analytics.
   * @param name - string type, name of the event
   * @param prop - object type, contains data captured on click of the event
   */
  trackSegmentEvent(name, prop) {
    // if (isProject) {
    //   prop['project_name'] = this.projectDetail ? this.projectDetail.name : '';
    //   prop['manager_name'] = this.getUserName();
    // }
    this.angulartics2Segment.eventTrack(name, prop);
  }
}
