import { createSelector, Selector } from '@ngxs/store';
import { ManagerStateModel } from './manager-state.model';
import { ManagerState } from './manager.state';

export class ManagerSelector {
  @Selector([ManagerState])
  static getManagerSquadTab(state: ManagerState) {
    return state['tab'];
  }

  @Selector([ManagerState])
  static isLoading(state: ManagerStateModel) {
    return state.loading;
  }

  @Selector([ManagerState])
  static managerPageCount(state: ManagerStateModel) {
    return {
      next_page: state.entities.next_page,
      per_page: state.entities.per_page,
    };
  }

  @Selector([ManagerState])
  static getManagerList(state: ManagerStateModel) {
    return state.userList;
  }

  @Selector([ManagerState])
  static getManagerListCount(state: ManagerStateModel) {
    return state?.entities?.total_count ? state?.entities?.total_count : 0;
  }

  @Selector([ManagerState])
  static getSearchedUsersList(state: ManagerStateModel) {
    return state.searchedUsersList;
  }

  @Selector([ManagerState])
  static getManagerFullResponse(state: ManagerStateModel) {
    return state.entities;
  }
}
