export class ManagerStateModel {
  entities: any;
  title: string;
  loading: boolean;
  userList: any;
  searchedUsersList: Array<any>;
}

export class ManagerSquadTabStateModel {
  tab: string;
}
