import { Observable, of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { DataService } from './data.service';
import { environment } from '../../environments/environment';
import { Constants } from '../shared/constants';
@Injectable({
  providedIn: 'root',
})
export class ManagerService {
  API_BASE_URL = environment.API_BASE_URL;
  managerTabStatus;
  managerID: number;
  constructor(public http: HttpClient, private dataService: DataService) {}

  getAllManager(page?, perPage?, query?, value?, min?) {
    let API = `${this.API_BASE_URL}${Constants.apiEndPoints.getAllManager}?page=${page}&per_page=${perPage}`;

    if (query) {
      API = `${API}&search=${query}`;
    }
    if (value) {
      API = `${API}&filter=status&value=${value}`;
    }

    if (min) {
      API = `${API}&min=${min}`;
    }

    return this.http
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => res),
        catchError((error: HttpErrorResponse) => {
          this.dataService.checkErrorStatus(error);
          return throwError(() => error.error);
        })
      );
  }

  addManagers(manager) {
    let API = `${this.API_BASE_URL}${Constants.apiEndPoints.inviteManager}`;

    return this.http
      .post(
        API,
        JSON.stringify(manager),
        this.dataService.getRequestOptionArgs('application/json')
      )
      .pipe(
        map((res) => res),
        catchError((error: any) => {
          this.dataService.checkErrorStatus(error);
          return throwError(() => error);
        })
      );
  }

  public updateManager(manager) {
    const API = `${this.API_BASE_URL}${Constants.apiEndPoints.getAllManager}/${this.managerID}`;
    return this.http
      .patch(
        API,
        JSON.stringify(manager),
        this.dataService.getRequestOptionArgs('application/json')
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => {
          this.dataService.checkErrorStatus(error);
          return throwError(() => error);
        })
      );
  }

  public getManagerById(id) {
    const API = `${this.API_BASE_URL}${Constants.apiEndPoints.getAllManager}/${id}`;
    return this.http
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          this.dataService.checkErrorStatus(error);
          return throwError(() => error);
        })
      );
  }

  public sendReInvite() {
    const invite = {
      invite_user_id: this.managerID,
    };
    const API = `${this.API_BASE_URL}${Constants.apiEndPoints.reInviteUser}`;
    return this.http
      .post(
        API,
        JSON.stringify(invite),
        this.dataService.getRequestOptionArgs('application/json')
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => {
          this.dataService.checkErrorStatus(error);
          return throwError(() => error);
        })
      );
  }

  public updateManagerStatus(id, value) {
    const API = `${
      this.API_BASE_URL
    }${Constants.apiEndPoints.archiveManager.replace(
      '[manager_id]',
      id
    )}${value}`;
    return this.http
      .post(
        API,
        JSON.stringify(''),
        this.dataService.getRequestOptionArgs('application/json')
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error: any) => {
          this.dataService.checkErrorStatus(error);
          return throwError(() => error);
        })
      );
  }
}
