import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ManagerService } from '../../../services/manager.service';
import { tap } from 'rxjs/operators';
import {
  AddManager,
  GetAllManager,
  GetManager,
  ReInviteUser,
  SetManagerSquadTab,
  UpdateManager,
  UpdateManagerStatus,
} from './manager.action';
import {
  ManagerSquadTabStateModel,
  ManagerStateModel,
} from './manager-state.model';

@State<ManagerStateModel>({
  name: 'managerstate',
  defaults: {
    entities: {},
    loading: false,
    title: 'manager',
    userList: [],
    searchedUsersList: [],
  },
})
@Injectable()
export class ManagerState {
  managerSquadTab: 'proManager-tab' | 'archivedManager-tab' | 'pod-tab';
  constructor(private managerService: ManagerService) {}

  @Action(SetManagerSquadTab)
  setLoaderState(
    { getState, setState }: StateContext<ManagerSquadTabStateModel>,
    { payload }: SetManagerSquadTab
  ) {
    this.managerSquadTab = payload as any;
    setState({
      ...getState(),
      tab: payload,
    });
  }

  @Action(GetAllManager)
  getAllDataFromState(
    ctx: StateContext<ManagerStateModel>,
    { page, perPage, searchText, value, min }
  ) {
    const state = ctx.getState();
    ctx.setState({ ...state, loading: true });

    return this.managerService
      .getAllManager(page, perPage, searchText, value, min)
      .pipe(
        tap((returnData: any) => {
          if (page === 1 && !searchText) {
            ctx.setState(this.reAssignManagerList(state, returnData));
          } else if (searchText) {
            if (
              this.managerSquadTab === 'proManager-tab' ||
              this.managerSquadTab === 'archivedManager-tab'
            ) {
              if (page === 1) {
                ctx.setState(this.reAssignManagerList(state, returnData));
              } else {
                ctx.setState(this.reAssignManagerList(state, returnData, true));
              }
            } else {
              // this.searchedUsersList = returnData?.data?.users;
              ctx.setState({
                ...state,
                loading: false,
                searchedUsersList: returnData?.data?.users,
              });
            }
          } else {
            ctx.setState(this.reAssignManagerList(state, returnData, true));
          }
        })
      );
  }

  reAssignManagerList(state, returnData, isConcat?) {
    return {
      ...state,
      entities:
        returnData && returnData.message === 'success' ? returnData.data : null,
      loading: false,
      userList: isConcat
        ? state.userList.concat(returnData?.data?.users)
        : returnData?.data?.users,
    };
  }

  @Action(AddManager)
  addDataToState(ctx: StateContext<any>, { payload }: AddManager) {
    const state = ctx.getState();
    ctx.setState({ ...state, loading: true });
    return this.managerService.addManagers(payload).pipe(
      tap((returnData: any) => {
        if (returnData?.message === 'success' && state.userList.length > 0) {
          state.userList.push(returnData.user);
          returnData.data['apiType'] = 'add';
        }

        ctx.setState({
          ...state,
          entities:
            returnData && returnData.message === 'success'
              ? returnData.data
              : null,
          loading: false,
        });
      })
    );
  }

  @Action(UpdateManager)
  updateDataOfState(ctx: StateContext<any>, { payload, id }: UpdateManager) {
    const state = ctx.getState();
    ctx.setState({ ...state, loading: true });
    return this.managerService.updateManager(payload).pipe(
      tap((returnData: any) => {
        if (returnData?.message === 'success') {
          const index = state.userList.map(({ id }) => id).indexOf(id);
          state.userList[index] = returnData.data.user;
        }

        ctx.setState({
          ...state,
          entities:
            returnData && returnData.message === 'success'
              ? returnData.data.user
              : null,
          loading: false,
          // userList: this.managerUserList,
        });
      })
    );
  }

  @Action(GetManager)
  getDataFromState(ctx: StateContext<any>, { id }: UpdateManager) {
    const state = ctx.getState();
    ctx.setState({ ...state, loading: true });
    return this.managerService.getManagerById(id).pipe(
      tap((returnData: any) => {
        ctx.setState({
          ...state,
          entities:
            returnData && returnData.message === 'success'
              ? returnData.data.user
              : null,
          loading: false,
          // userList: this.managerUserList,
        });
      })
    );
  }

  @Action(ReInviteUser)
  sendReInvite(ctx: StateContext<any>) {
    const state = ctx.getState();
    ctx.setState({ ...state, loading: true });
    return this.managerService.sendReInvite().pipe(
      tap((returnData: any) => {
        ctx.setState({
          ...state,
          entities: returnData,
          loading: false,
          // userList: this.managerUserList,
        });
      })
    );
  }

  @Action(UpdateManagerStatus)
  updateManagerStatusToArchiveUnArchive(
    ctx: StateContext<any>,
    { managerData, type }: UpdateManagerStatus
  ) {
    const state = ctx.getState();
    ctx.setState({ ...state, loading: true });
    return this.managerService.updateManagerStatus(managerData.id, type).pipe(
      tap((returnData: any) => {
        let index = -1;
        if (returnData.message === 'success') {
          index = state.userList.map(({ id }) => id).indexOf(managerData.id);
          managerData.archived_for_partner = type === true ? false : true;
          state.userList[index] = managerData;
        }
        ctx.setState({
          ...state,
          entities: state.userList[index],
          loading: false,
          // userList: this.managerUserList,
        });
      })
    );
  }

  //   @Action(DeleteManagers)
  //   deleteDataFromState(ctx: StateContext<ManagerStateModel>, { id }: DeleteManagers) {
  //     return this._du.deleteUser(id).pipe(
  //       tap((returnData) => {
  //         const state = ctx.getState();
  //         //Here we will create a new Array called filteredArray which won't contain the given id and set it equal to state.todo
  //         const filteredArray = state.managers.filter(
  //           (contents) => contents.id !== id
  //         );

  //         ctx.setState({
  //           ...state,
  //           managers: filteredArray,
  //         });
  //       })
  //     );
  //   }
}
