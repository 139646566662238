import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manager-home',
  templateUrl: './manager-home.component.html',
  styleUrls: ['./manager-home.component.scss'],
})
export class ManagerHomeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
