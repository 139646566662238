import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { CreatePod, GetPods, UpdatePod } from '../pod/pod.action';
import { PodService } from 'src/app/services/pod.service';
import { PodModel } from './pod.model';

@State<PodModel>({
  name: 'podstate',
  defaults: {
    data: {},
    loading: false,
    title: 'pod',
    storeProjects: {},
    podList: [],
  },
})
@Injectable()
export class PodState {
  constructor(private podService: PodService, private store: Store) {}

  // @Selector([PodState])
  // static getPodList(state: PodModel) {
  //   return state.data;
  // }

  @Action(GetPods)
  getDataFromState(ctx: StateContext<PodModel>, { page, perPage }) {
    const state = ctx.getState();
    ctx.setState({ ...state, loading: true });
    return this.podService.getPods(page, perPage).pipe(
      tap((returnData: any) => {
        ctx.patchState({
          ...state,
          loading: false,
          data:
            returnData && returnData.message === 'success'
              ? returnData.data
              : null,
          podList: returnData?.data?.pods,
          storeProjects:
            returnData && returnData.message === 'success'
              ? returnData?.data?.pods?.find((pod) => {
                  if (pod.is_store_pod === true) {
                    return pod;
                  }
                })
              : [],
        });
      })
    );
  }

  @Action(UpdatePod)
  updatePod(ctx: StateContext<PodModel>, { data, id, page }) {
    const state = ctx.getState();
    ctx.setState({ ...state, loading: false });
    return this.podService.updatePod(data, id).pipe(
      tap((returnData: any) => {
        // this.store.dispatch(new GetPods(page, 15));
        if (returnData?.message === 'success') {
          const index = state.podList.map(({ id }) => id).indexOf(id);
          state.podList[index] = returnData.data.pod;
        }

        ctx.patchState({
          ...state,
          loading: false,
          data:
            returnData && returnData.message === 'success'
              ? returnData.data
              : null,
        });
      })
    );
  }

  @Action(CreatePod)
  createPod(ctx: StateContext<PodModel>, { data, page }) {
    const state = ctx.getState();
    ctx.setState({ ...state, loading: false });
    return this.podService.createPod(data).pipe(
      tap((returnData: any) => {
        if (returnData?.message === 'success' && state?.podList?.length > 0) {
          state.podList.push(returnData?.data?.pod);
        }
        // this.store.dispatch(new GetPods(page, 15));
        ctx.patchState({
          ...state,
          loading: false,
          data:
            returnData && returnData.message === 'success'
              ? returnData.data
              : null,
        });
      })
    );
  }
}
